import styled from "@emotion/styled";

import { spacingLarge } from "@medi24-da2c/web-ui/design-tokens";

const Main = styled.main`
  padding: ${spacingLarge};
  padding-top: 0;
  overflow: hidden;
`;

export default Main;
