import { useQuery } from "react-query";
import fetchData from "./fetchData";

const ABI_LANGUAGES_QUERY_KEY = "abiLanguages";

export async function getAbiLanguages() {
  const abiLanguages = await fetchData("/abiLanguages", { method: "GET" });
  const normalizedAbiLanguages = abiLanguages.map(
    (abiLanguage) => abiLanguage.languageValue
  );
  return normalizedAbiLanguages;
}

export function useGetAbiLanguages() {
  const { data: abiLanguages } = useQuery(
    ABI_LANGUAGES_QUERY_KEY,
    getAbiLanguages
  );
  return abiLanguages ? abiLanguages : [];
}
