// Simple wrapper around fetch with base URL configured
// Used to fetch data from mock API

import axios from "axios";

const BASE_API_PATH = "http://localhost:4000";

async function fetchData(url, { method, body, responseType }) {
  const { data } = await axios({
    method,
    url: `${BASE_API_PATH}${url}`,
    responseType,
    data: body,
  });
  return data;
}

export default fetchData;
