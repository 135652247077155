import { ErrorBoundary, Headline, Link } from "@medi24-da2c/web-ui";

function PageNotFound() {
  return (
    <ErrorBoundary>
      <Headline>404 Page Not Found</Headline>
      <Link to="/">Go to Home Page</Link>
    </ErrorBoundary>
  );
}

export default PageNotFound;
