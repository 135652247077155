import PropTypes from "prop-types";
import { useMatch } from "react-router-dom";
import { useFocusRing } from "@react-aria/focus";

import Link from "./Link";
import Icon from "./Icon";
import Label from "./Label";
import FocusIndicator from "./FocusIndicator";

function SidebarLink({ to, label, icon }) {
  // Determines whether path is EXACTLY matched
  const isActive = !!useMatch(to);
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <Link to={to} {...(isActive && { "aria-current": "page" })} {...focusProps}>
      <FocusIndicator isFocusVisible={isFocusVisible}>
        <Icon isActive={isActive}>{icon}</Icon>
        <Label isActive={isActive}>{label}</Label>
      </FocusIndicator>
    </Link>
  );
}

SidebarLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
};

export default SidebarLink;
