function getLabels(ratings) {
  return Object.keys(sortDates(ratings));
}

function sortDates(obj) {
  return Object.keys(obj)
    .sort()
    .reduce(function (result, key) {
      result[key] = obj[key];
      return result;
    }, {});
}

function createChartDataset(ratings, title) {
  let labels = [];
  if (ratings) {
    labels = getLabels(ratings);
  }

  return {
    labels,
    datasets: [
      {
        label: title,
        data: labels.map((key) => ratings[key]),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
}

export default createChartDataset;
