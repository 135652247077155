// Split codes into array on unlimited number of spaces and comma
// Remove empty strings from a list

function splitCodes(codes) {
  return codes
    .trim()
    .split(/\s*(?:,|$)\s*/)
    .filter(Boolean);
}

export default splitCodes;
