import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { Text } from "@medi24-da2c/web-ui";
import {
  spacingMedium,
  fontFormMedium,
  fontWeightBold,
} from "@medi24-da2c/web-ui/design-tokens";

const Wrapper = styled.section`
  margin-top: ${spacingMedium};

  &:last-of-type {
    margin-bottom: 3.6rem;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(Text)`
  font-size: ${fontFormMedium};
  font-weight: ${fontWeightBold};
  margin: 0;
`.withComponent("h4");

function DetailsSection({ title, extraHeaderContent, children, ...props }) {
  return (
    <Wrapper {...props}>
      <Header>
        <Title>{title}</Title>
        {extraHeaderContent}
      </Header>
      {children}
    </Wrapper>
  );
}

DetailsSection.propTypes = {
  title: PropTypes.string.isRequired,
  extraHeaderContent: PropTypes.node,
  children: PropTypes.node,
};

export default DetailsSection;
