function Chart() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.599 31.9c-.938 0-1.7-.763-1.7-1.7v-18c0-.938.762-1.7 1.7-1.7h4.3V8.6a.5.5 0 011 0v1.9h12.2V8.6a.5.5 0 011 0v1.9h4.299c.938 0 1.702.763 1.702 1.7v18c0 .938-.764 1.7-1.702 1.7H8.599zm0-20.4a.7.7 0 00-.7.7v18a.7.7 0 00.7.7h22.799a.7.7 0 00.7-.7v-18a.7.7 0 00-.7-.7h-4.299v1.9a.5.5 0 01-1 0v-1.9h-12.2v1.9a.5.5 0 01-1 0v-1.9h-4.3zM12.2 24.701a.502.502 0 01-.354-.855l4.8-4.799a.492.492 0 01.653-.046l4.455 3.339 5.691-5.692a.501.501 0 11.709.705l-6 6a.501.501 0 01-.354.146.488.488 0 01-.299-.101l-4.454-3.339-4.493 4.493a.5.5 0 01-.354.149z"
      />
    </svg>
  );
}

export default Chart;
