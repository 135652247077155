import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { Headline, Text, spacingLarge } from "@medi24-da2c/web-ui";

const Wrapper = styled.section`
  padding: ${spacingLarge};
`;

function AuthErrorMessage({ headline, message }) {
  return (
    <Wrapper>
      <Headline>{headline}</Headline>
      <Text>{message}</Text>
    </Wrapper>
  );
}

AuthErrorMessage.propTypes = {
  headline: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default AuthErrorMessage;
