import PropTypes from "prop-types";
import DetailsSection from "./DetailsSection";
import StatusLabel from "./StatusLabel";

function ProductDetailsSection({ title, enabled, children, ...props }) {
  return (
    <DetailsSection
      {...props}
      title={title}
      extraHeaderContent={
        <StatusLabel enabled={enabled}>
          {enabled ? "Enabled" : "Disabled"}
        </StatusLabel>
      }
    >
      {children}
    </DetailsSection>
  );
}

ProductDetailsSection.propTypes = {
  title: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export default ProductDetailsSection;
