import styled from "@emotion/styled";

import Fields from "./Fields";
import FieldsLegend from "./FieldsLegend";
import Loader from "./Loader";

const BasicForm = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

BasicForm.Fields = Fields;
BasicForm.FieldsLegend = FieldsLegend;
BasicForm.Loader = Loader;

export default BasicForm;
