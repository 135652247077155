import { useState } from "react";
import PropTypes from "prop-types";
import { saveAs } from "file-saver";

import { INITIAL_STEP, ERROR_STEP } from "constants/steps";
import { getStatisticsByPartnerId } from "api/statistics";
import DownloadCsvControls from "components/DownloadCsvControls";
import BasicStep from "../BasicStep";
import csvFileName from "./csvFileName";

function SuccessStep({ setStep, partnerDetails }) {
  const [loading, setLoading] = useState(false);

  const {
    id: partnerId,
    name: partnerName,
    code: partnerCode,
  } = partnerDetails;

  async function handleDownload() {
    setLoading(true);

    try {
      const statisticsBatch = await getStatisticsByPartnerId(partnerId, [
        localStorage.getItem("rangeStartAt"),
        localStorage.getItem("rangeEndAt"),
      ]);
      saveAs(
        statisticsBatch,
        csvFileName(partnerName, partnerCode, Date.now())
      );
      setLoading(false);
    } catch {
      setLoading(false);
      setStep(ERROR_STEP);
    }
  }

  function renderControls() {
    return (
      <>
        {loading && <BasicStep.Loader />}
        <DownloadCsvControls
          onDownloadButtonClick={handleDownload}
          onCloseButtonClick={() => setStep(INITIAL_STEP)}
        />
      </>
    );
  }

  return (
    <BasicStep headline="Success!" controls={renderControls()}>
      <BasicStep.Text>
        Activation codes usage statistics gathered successfully.
      </BasicStep.Text>
    </BasicStep>
  );
}

SuccessStep.propTypes = {
  setStep: PropTypes.func.isRequired,
  range: PropTypes.array,
  partnerDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
};

export default SuccessStep;
