import PropTypes from "prop-types";

import { ErrorBoundary, AppBar } from "@medi24-da2c/web-ui";
import { Logotype } from "@medi24-da2c/web-ui/default-theme";
import AppRoutes from "../AppRoutes";
import Sidebar from "../Sidebar";
import Wrapper from "./Wrapper";
import SidebarAndMainWrapper from "./SidebarAndMainWrapper";
import Main from "./Main";
import LogOut from "./LogOut";

function App({ logout }) {
  return (
    <ErrorBoundary>
      <Wrapper>
        <AppBar
          logotype={<Logotype />}
          fullWidth
          mainContent={<LogOut logout={logout} />}
        />
        <SidebarAndMainWrapper>
          <Sidebar />
          <Main>
            <AppRoutes />
          </Main>
        </SidebarAndMainWrapper>
      </Wrapper>
    </ErrorBoundary>
  );
}

App.propTypes = {
  logout: PropTypes.func,
};

export default App;
