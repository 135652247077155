import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import Root from "./components/Root";
import * as serviceWorker from "./serviceWorker";
const queryClient = new QueryClient();
ReactDOM.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <Root />
    </QueryClientProvider>
  </StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();

// test deploy
