export const COUNTRY_CODES = {
  at: "Austria",
  au: "Australia",
  ch: "Switzerland",
  cn: "China",
  de: "Germany",
  es: "Spain",
  fr: "France",
  hk: "Hong Kong",
  gb: "Great Britain",
  pt: "Portugal",
  hr: "Croatia",
  si: "Slovenia",
  lk: "Sri Lanka",
  ec: "Ecuador",
  my: "Malaysia",
  kw: "Kuwait",
  it: "Italy",
  sg: "Singapore",
  th: "Thailand",
  tw: "Taiwan",
  ua: "Ukraine",
  vn: "Vietnam",
};
