function mapSessionsToChartDataset(sessions) {
  if (!sessions) {
    return;
  }

  let sessionData = {};

  sessions.forEach((session) => {
    sessionData[session["period"]] = session["numberOfSessions"];
  });

  return sessionData;
}

export default mapSessionsToChartDataset;
