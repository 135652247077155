import { MsalAuthProvider, LoginType } from "react-aad-msal";
import "regenerator-runtime/runtime";

const config = {
  auth: {
    authority: "https://login.microsoftonline.com/common",
    clientId: process.env.REACT_APP_AUTH_APPLICATION_ID,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

const authParams = {
  scopes: ["User.Read"],
};

const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + "/auth.html",
};

const authProvider = new MsalAuthProvider(config, authParams, options);

export default authProvider;
