import PropTypes from "prop-types";

import { SUCCESS_STEP } from "../../constants/steps";
import ExistingPartnerSelectForm from "../ExistingPartnerSelectForm";
import GatherAllStats from "./GatherAllStats";

function InitialStep({ setStep, setPartnerDetails }) {
  return (
    <>
      <ExistingPartnerSelectForm
        setStep={setStep}
        setPartnerDetails={setPartnerDetails}
        submitButtonLabel="Get selected partner statistics"
        nextStep={SUCCESS_STEP}
      />
      <GatherAllStats setStep={setStep} />
    </>
  );
}

InitialStep.propTypes = {
  setStep: PropTypes.func.isRequired,
  setPartnerDetails: PropTypes.func.isRequired,
};

export default InitialStep;
