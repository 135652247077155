import { ErrorBoundary, Headline } from "@medi24-da2c/web-ui";
import { Ratings } from "components/RatingsAndSessions";

function RatingsPage() {
  return (
    <ErrorBoundary>
      <Headline>Ratings</Headline>
      <Ratings />
    </ErrorBoundary>
  );
}

export default RatingsPage;
