import { EXPIRY_DATE_TYPES } from "../../constants/expiryDateType";
import mapExpiryDateTypeOptions from "./mapExpiryDateTypeOptions";

const expiryDateTypeOptions = [
  {
    value: "",
    label: "Expiry date type",
    disabled: true,
  },
].concat(mapExpiryDateTypeOptions(Object.keys(EXPIRY_DATE_TYPES)));

export default expiryDateTypeOptions;
