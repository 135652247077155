import { useEffect, useState, useCallback } from "react";
import { Formik } from "formik";
import { Button } from "@medi24-da2c/web-ui";
import { DatePicker, Input } from "react-rainbow-components";
import {
  useGetAllRatings,
  getAllRatings,
  useGetRatingsDistribution,
  getRatingsDistribution,
} from "api/sessionsRatings";
import { useGetPartners } from "api/partners";
import Cards from "components/Cards";
import BasicForm from "../BasicForm";
import FormSelect from "../FormSelect";
import Card, { CARD_REGULAR } from "../Card";
import PartnerMultiselect from "./Multiselect";
import { ChartComponent, CHART_TYPES } from "./ChartComponent";
import BarChart from "./BarChart";
import validateForm from "./validateForm";
import timePeriodOptions from "./timePeriodOptions";
import createChartDataset from "./createChartDataset";
import createChartOptions from "./createChartOptions";
import mapNumbersToProcentage from "./mapNumbersToProcentage";
import calculateAverageRating from "./calculateAverageRating";

const inputStyles = {
  width: 300,
  marginTop: 10,
};

function Ratings() {
  const chartLabel = "Average Rating";
  const userDistributionChartLabel = "% wise ratings";
  const options = createChartOptions("Ratings");
  const optionsUserDistribution = createChartOptions(
    "User Distribution for Ratings",
    { indexAxis: "y" }
  );

  let today = new Date();
  let sixMonthsEarlier = new Date().setMonth(today.getMonth() - 6);

  const [loading, setLoading] = useState(false);
  const [excludeUsersWithName, setExcludeUsersWithName] = useState(false);
  const [disableExcludedNameField, setDisableExcludedNameField] =
    useState(true);
  const [excludedName, setExcludedName] = useState("test");
  const [partnersIds, setPartnersIds] = useState("");
  const [sinceStartPeriod, setSinceStartPeriod] = useState(false);
  const [isDatePickerDisabled, setIsDatePickerDisabled] = useState(false);
  const [existingPartners, setExistingPartners] = useState([]);
  const [upTodate, setUpToDate] = useState(today.toISOString());
  const [startDate, setStartDate] = useState(
    new Date(sixMonthsEarlier).toISOString()
  );

  const { partners } = useGetPartners();
  const { ratings } = useGetAllRatings(startDate, upTodate, "");
  const { records } = useGetRatingsDistribution(startDate, upTodate, "");

  const [data, setData] = useState(createChartDataset(ratings, chartLabel));
  const [averageRating, setAverageRating] = useState(
    calculateAverageRating(ratings)
  );
  const [ratingsDistributionData, setRatingsDistributionData] = useState(
    createChartDataset(records, userDistributionChartLabel)
  );

  useEffect(() => {
    setData(createChartDataset(ratings, chartLabel));
    setAverageRating(calculateAverageRating(ratings));
  }, [ratings]);

  useEffect(() => {
    setRatingsDistributionData(
      createChartDataset(
        mapNumbersToProcentage(records),
        userDistributionChartLabel
      )
    );
  }, [records]);

  useEffect(() => {
    setExistingPartners(partners);
  }, [partners]);

  // values.timePeriod: ""
  async function handleSubmit(values) {
    setLoading(true);
    try {
      const startFrom = new Date(startDate).toISOString();
      const endDate = new Date(upTodate).toISOString();
      let period = "";
      let excludeNamePattern = "";

      if (values["timePeriod"]) {
        period = values["timePeriod"];
      }

      if (excludeUsersWithName && excludedName) {
        excludeNamePattern = excludedName;
      }

      getAllRatings(
        startFrom,
        endDate,
        partnersIds,
        period,
        excludeNamePattern
      ).then((newRatings) => {
        setData(createChartDataset(newRatings, chartLabel));
        setAverageRating(calculateAverageRating(newRatings));
        setLoading(false);
      });

      getRatingsDistribution(
        startFrom,
        endDate,
        partnersIds,
        period,
        excludeNamePattern
      ).then((newRecords) => {
        setRatingsDistributionData(
          createChartDataset(mapNumbersToProcentage(newRecords), chartLabel)
        );
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
    }
  }

  function updateStartDate(value) {
    setStartDate(new Date(value).toISOString());
  }

  function updateUpToDate(value) {
    setUpToDate(new Date(value).toISOString());
  }

  function setExcludeName() {
    setExcludeUsersWithName(!excludeUsersWithName);
    setDisableExcludedNameField(!disableExcludedNameField);
  }

  function updateExcludedName(value) {
    if (value && value.target) {
      setExcludedName(value.target.value);
    }
  }

  function setStartPeriod() {
    if (sinceStartPeriod === false) {
      setStartDate(new Date(2020, 10, 9).toISOString());
      setUpToDate(new Date().toISOString());
      setIsDatePickerDisabled(true);
    } else {
      setIsDatePickerDisabled(false);
    }
    setSinceStartPeriod(!sinceStartPeriod);
  }

  const updatePartners = useCallback(
    (args) => {
      let partnersList = [];
      args.forEach((partner) => partnersList.push(partner["value"]));
      setPartnersIds(partnersList.join());
    },
    [setPartnersIds]
  );

  return (
    <div>
      <div>
        <Formik
          initialValues={{
            timePeriod: "",
          }}
          validate={validateForm}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, dirty }) => (
            <BasicForm onSubmit={handleSubmit} noValidate>
              <BasicForm.Fields>
                <Cards>
                  <Card title="Ratings Parameters" variant={CARD_REGULAR}>
                    <PartnerMultiselect
                      partners={existingPartners}
                      onPartnersChange={updatePartners}
                    />

                    <FormSelect
                      name="timePeriod"
                      label="Select time period"
                      options={timePeriodOptions}
                    />

                    <Input
                      value={sinceStartPeriod}
                      className="rainbow-m-around_medium"
                      type="checkbox"
                      label="Since product launch"
                      onChange={setStartPeriod}
                    />

                    <div>
                      <DatePicker
                        formatStyle="medium"
                        value={startDate}
                        label="Start Date"
                        disabled={isDatePickerDisabled}
                        onChange={updateStartDate}
                        data-testid="data-start-date"
                      />
                    </div>
                    <div>
                      <DatePicker
                        formatStyle="medium"
                        value={upTodate}
                        label="Up To Date"
                        disabled={isDatePickerDisabled}
                        onChange={updateUpToDate}
                        data-testid="data-end-date"
                      />
                    </div>

                    <br />

                    <div>
                      <Button
                        type="submit"
                        disabled={loading}
                        data-testid="ratings-submit-button"
                      >
                        Refresh
                      </Button>
                    </div>
                  </Card>
                  <Card title="Additional Settings" variant={CARD_REGULAR}>
                    <Input
                      value={excludeUsersWithName}
                      name="excludeName"
                      className="rainbow-m-around_medium"
                      type="checkbox"
                      label="Exclude Users With Name Pattern"
                      onChange={setExcludeName}
                    />
                    <Input
                      placeholder="test"
                      type="text"
                      className="rainbow-p-around_medium"
                      value={excludedName}
                      style={inputStyles}
                      onChange={updateExcludedName}
                      disabled={disableExcludedNameField}
                    />
                  </Card>
                </Cards>
              </BasicForm.Fields>

              {loading && <BasicForm.Loader />}

              <br />
            </BasicForm>
          )}
        </Formik>
      </div>

      <h1>Average Rating: {averageRating}</h1>
      <br />

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "45%" }}>
          <ChartComponent
            options={options}
            data={data}
            chartType={CHART_TYPES.LINE_CHART}
          />
        </div>

        <div style={{ width: "45%" }}>
          <BarChart
            options={optionsUserDistribution}
            data={ratingsDistributionData}
          />
        </div>
      </div>
    </div>
  );
}

export default Ratings;
