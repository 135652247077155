import styled from "@emotion/styled";

import { borderWidthThin } from "@medi24-da2c/web-ui/design-tokens";
import { colorAnthraciteLight } from "@medi24-da2c/web-ui/default-theme";

const Wrapper = styled.section`
  display: grid;
  grid-template-rows: auto 1fr auto;
  background-color: ${(props) => props.theme.general.bgrColor};
  border-right: ${borderWidthThin} solid ${colorAnthraciteLight};
  width: 20rem;
`;

export default Wrapper;
