import { QR_CODE_TYPES } from "../../constants/qrCodeTypes";
import mapQrCodeTypeOptions from "./mapQrCodeTypeOptions";

const qrCodeTypeOptions = [
  {
    value: "",
    label: "QR code type",
    disabled: true,
  },
].concat(mapQrCodeTypeOptions(Object.keys(QR_CODE_TYPES)));

export default qrCodeTypeOptions;
