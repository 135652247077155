import mapLanguageOptions from "./mapLanguageOptions";

const defaultLanguageOptions = [
  {
    value: "",
    label: "This value is not used anywhere",
    disabled: true,
  },
].concat(mapLanguageOptions());

export default defaultLanguageOptions;
