import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { Text } from "@medi24-da2c/web-ui";
import {
  spacingMedium,
  spacingSmall,
  fontWeightBold,
} from "@medi24-da2c/web-ui/design-tokens";

const Row = styled(Text)`
  display: flex;
  margin: ${spacingSmall} 0;
`;

const Label = styled.span`
  width: 16rem;
  min-width: 16rem;
  padding-right: ${spacingMedium};
`;

const Value = styled.span`
  font-weight: ${fontWeightBold};
`;

function Detail({ label, value }) {
  return (
    <Row>
      <Label>{label}:</Label>
      <Value>{value || "—"}</Value>
    </Row>
  );
}

Detail.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
};

export default Detail;
