const MAX_NUMBER_OF_CODES_TO_GENERATE = 100000;

function validateForm(values) {
  let errors = {};

  // existingpartnerId
  if (values.existingPartnerId === "") {
    errors.existingPartnerId = "Select partner from a list";
  }

  // numberOfActivationCodes
  if (
    isNaN(values.numberOfActivationCodes) ||
    values.numberOfActivationCodes === ""
  ) {
    errors.numberOfActivationCodes = "Enter a number";
  } else if (
    values.numberOfActivationCodes < 1 ||
    values.numberOfActivationCodes > MAX_NUMBER_OF_CODES_TO_GENERATE
  ) {
    errors.numberOfActivationCodes = "Number must be between 1 and 100 000";
  }

  return errors;
}

export default validateForm;
