function mapNumbersToProcentage(recordsMap) {
  let allRecords = 0;

  if (!recordsMap) {
    return recordsMap;
  }

  let keys = Object.keys(recordsMap);

  keys.forEach((key) => (allRecords += recordsMap[key]));
  keys.forEach(
    (key) =>
      (recordsMap[key] = ((recordsMap[key] / allRecords) * 100).toFixed(2))
  );

  return recordsMap;
}

export default mapNumbersToProcentage;
