function denormalizedPartner(partner) {
  const {
    name,
    contactEmail,
    supportEmail,
    privacyEmail,
    country,
    defaultLanguage,
    debugMode,
    botName,
    partnerSubdomain,
    logoutUrl,
    partnerCode,
    qrCodeType,
    landingPageUrl,
    hasHotline,
    hotlineNumber,
    hotlineSecondNumber,
    hasHealthcareWebsite,
    healthcareWebsite,
    hasSymptomChecker,
    hasDoctorChat,
    doctorChatPartnerName,
    doctorChatPhysicianCountry,
    doctorChatAvailableLanguages,
    hasTeleconsultation,
    teleconsultationNumber,
    teleconsultationWebsite,
    teleconsultationCountry,
    teleconsultationMode,
    teleconsultationMyDocGroupId,
    teleconsultationMyDocClientId,
    teleconsultationMyDocClientSecret,
    teleconsultationMyDocGetRegistrationFieldsUrl,
    teleconsultationMyDocCreateInvitationUrl,
    hasSelfRegistration,
    selfRegistrationEligibilityCheckType,
    selfRegistrationMaxNumberOfActivationCodesPerUserKey,
    selfRegistrationResetLeftActivationCodesDateType,
    selfRegistrationResetLeftActivationCodesNumberOfDays,
    selfRegistrationResetLeftActivationCodesDate,
    selfRegistrationExpiryDateType,
    selfRegistrationNumberOfDays,
    selfRegistrationDate,
  } = partner;

  return {
    partner: {
      name,
      contactEmail,
      supportEmail,
      privacyEmail,
      country,
      defaultLanguage,
      debugMode,
      botName,
      partnerSubdomain,
      logoutUrl,
      partnerCode,
      qrCodeType,
      landingPageUrl,
    },
    product: {
      hotline: {
        enabled: hasHotline,
        number: hotlineNumber,
        secondNumber: hotlineSecondNumber,
      },
      teleconsultation: {
        enabled: hasTeleconsultation,
        number: teleconsultationNumber,
        link: teleconsultationWebsite,
        country: teleconsultationCountry,
        mode: teleconsultationMode,
        myDocGroupId: teleconsultationMyDocGroupId,
        myDocClientId: teleconsultationMyDocClientId,
        myDocClientSecret: teleconsultationMyDocClientSecret,
        myDocGetRegistrationFieldsUrl:
          teleconsultationMyDocGetRegistrationFieldsUrl,
        myDocCreateInvitationUrl: teleconsultationMyDocCreateInvitationUrl,
      },
      website: {
        enabled: hasHealthcareWebsite,
        link: healthcareWebsite,
      },
      symptomChecker: {
        enabled: hasSymptomChecker,
      },
      doctorChat: {
        ...(hasDoctorChat
          ? {
              enabled: true,
              partnerName: doctorChatPartnerName,
              availableLanguages: [...doctorChatAvailableLanguages],
              physicianCountry: doctorChatPhysicianCountry,
            }
          : {
              enabled: false,
              availableLanguages: [],
            }),
      },
      selfRegistration: {
        enabled: hasSelfRegistration,
        eligibilityCheckType: selfRegistrationEligibilityCheckType,
        maxNumberOfActivationCodesPerUserKey:
          selfRegistrationMaxNumberOfActivationCodesPerUserKey,
        resetLeftActivationCodes: {
          resetLeftActivationCodesDateType:
            selfRegistrationResetLeftActivationCodesDateType,
          numberOfDays: selfRegistrationResetLeftActivationCodesNumberOfDays,
          date: selfRegistrationResetLeftActivationCodesDate,
        },
        expiryDateType: selfRegistrationExpiryDateType,
        numberOfDays: selfRegistrationNumberOfDays,
        date: selfRegistrationDate,
        credentialsImporter: {
          enabled: false,
        },
      },
    },
  };
}

export default denormalizedPartner;
