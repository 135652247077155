import PropTypes from "prop-types";

import Input from "./Input";
import Option from "./Option";
import Icon from "./Icon";

function FormSelect({ label, disabled, options, ...props }) {
  return (
    <Input
      as="select"
      label={label}
      disabled={disabled}
      icon={<Icon disabled={disabled} />}
      {...props}
    >
      {options.map(({ value, label, disabled = false }) => (
        <Option key={value} value={value} disabled={disabled}>
          {label}
        </Option>
      ))}
    </Input>
  );
}

FormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      disabled: PropTypes.bool,
    })
  ).isRequired,
};

export default FormSelect;
