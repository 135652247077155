import { teleconsultationModeToLabel } from "../../utils/teleconsultationModeToLabel";

function mapTeleconsultationModeOptions(teleconsultationModes) {
  return teleconsultationModes.map((teleconsultationMode) => {
    return {
      value: teleconsultationMode,
      label: teleconsultationModeToLabel(teleconsultationMode),
    };
  });
}

export default mapTeleconsultationModeOptions;
