import { timePeriodCodeToName } from "utils/timePeriodCodeToName";

function mapTimePeriodOptions(timePeriodNameCodes) {
  return timePeriodNameCodes.map((code) => {
    return {
      value: code,
      label: timePeriodCodeToName(code),
    };
  });
}

export default mapTimePeriodOptions;
