import { botCodeToName } from "utils/botCodeToName";

function mapBotOptions(botNameCodes) {
  return botNameCodes.map((code) => {
    return {
      value: code,
      label: botCodeToName(code),
    };
  });
}

export default mapBotOptions;
