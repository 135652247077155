import { ErrorBoundary } from "@medi24-da2c/web-ui";
import Wrapper from "./Wrapper";
import Headline from "./Headline";
import Navigation from "./Navigation";
import Copyright from "./Copyright";

function Sidebar() {
  return (
    <ErrorBoundary>
      <Wrapper>
        <Headline>Admin dashboard</Headline>
        <Navigation />
        <Copyright />
      </Wrapper>
    </ErrorBoundary>
  );
}

export default Sidebar;
