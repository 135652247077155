import { useState } from "react";
import PropTypes from "prop-types";
import { saveAs } from "file-saver";

import { INITIAL_STEP, ERROR_STEP } from "constants/steps";
import { getActivationCodesBatch } from "api/activationCodes";
import DownloadCsvControls from "components/DownloadCsvControls";
import BasicStep from "../BasicStep";
import csvFileName from "./csvFileName";

function SuccessStep({ setStep, codesDetails }) {
  const [loading, setLoading] = useState(false);

  const { numberOfCodes, partnerName, partnerCode, batchId } = codesDetails;

  async function handleDownload() {
    setLoading(true);

    try {
      const activationCodesBatch = await getActivationCodesBatch(batchId);
      saveAs(
        activationCodesBatch,
        csvFileName(partnerName, partnerCode, batchId, Date.now())
      );
      setLoading(false);
    } catch {
      setLoading(false);
      setStep(ERROR_STEP);
    }
  }

  function renderControls() {
    return (
      <>
        {loading && <BasicStep.Loader />}
        <DownloadCsvControls
          onDownloadButtonClick={handleDownload}
          onCloseButtonClick={() => setStep(INITIAL_STEP)}
        />
      </>
    );
  }

  return (
    <BasicStep headline="Success!" controls={renderControls()}>
      <BasicStep.Text>
        <strong>{numberOfCodes}</strong> additional{" "}
        {numberOfCodes > 1 ? "codes have" : "code has"} been generated for an
        existing partner{" "}
        <strong>
          {partnerName} ({partnerCode})
        </strong>
        .
      </BasicStep.Text>
    </BasicStep>
  );
}

SuccessStep.propTypes = {
  setStep: PropTypes.func.isRequired,
  codesDetails: PropTypes.shape({
    numberOfCodes: PropTypes.number.isRequired,
    partnerName: PropTypes.string.isRequired,
    partnerCode: PropTypes.string.isRequired,
    preGenerated: PropTypes.bool,
    batchId: PropTypes.string.isRequired,
  }),
};

export default SuccessStep;
