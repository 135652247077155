import trimPartnerName from "./trimPartnerName";

function existingPartnersOptions(partners) {
  const DEFAULT_OPTION = {
    value: "",
    label: "Partner name",
    disabled: true,
  };

  let partnersOptions = [DEFAULT_OPTION];

  if (partners) {
    const existingPartners = partners.map((partner) => ({
      value: partner.id,
      label: `${trimPartnerName(partner.name)} (${partner.code})`,
    }));

    partnersOptions = [...partnersOptions, ...existingPartners];
  }

  return partnersOptions;
}

export default existingPartnersOptions;
