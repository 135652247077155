const NAME_TOO_SHORT_ERROR = "Name must be longer than 3 characters";
const NAME_TOO_LONG_ERROR = "Name must be shorter than 100 characters";
const PHONE_TOO_SHORT_ERROR = "Phone number must be longer than 4 characters";
const PHONE_TOO_LONG_ERROR = "Phone number must be shorter than 30 characters";
const URL_TOO_SHORT_ERROR = "URL must be longer than 4 characters";
const URL_TOO_LONG_ERROR = "URL must be shorter than 512 characters";

function validateForm(values) {
  let errors = {};

  // name
  if (values.name === "") {
    errors.name = "Enter partner's name";
  } else if (values.name.length < 3) {
    errors.name = NAME_TOO_SHORT_ERROR;
  } else if (values.name.length > 100) {
    errors.name = NAME_TOO_LONG_ERROR;
  }

  // contactEmail
  if (values.contactEmail === "") {
    errors.contactEmail = "Enter partner's contact email";
  } else if (
    !/^[a-zA-Z0-9_!#$%&’*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$/g.test(
      values.contactEmail
    )
  ) {
    errors.contactEmail = "Enter valid email";
  } else if (values.contactEmail.length > 100) {
    errors.contactEmail = "Email must be shorter than 100 characters";
  }

  // country
  if (values.country === "") {
    errors.country = "Select country from a list";
  }

  // defaultLanguage
  if (values.defaultLanguage === "") {
    errors.defaultLanguage = "Select language from a list";
  }

  // botName
  if (values.botName === "") {
    errors.botName = "Select bot name from a list";
  }

  // hotline
  if (values.hasHotline) {
    if (values.hotlineNumber === "") {
      errors.hotlineNumber = "Enter hotline phone number";
    } else if (values.hotlineNumber.length < 4) {
      errors.hotlineNumber = PHONE_TOO_SHORT_ERROR;
    } else if (values.hotlineNumber.length > 30) {
      errors.hotlineNumber = PHONE_TOO_LONG_ERROR;
    }
    if (values.hotlineSecondNumber) {
      if (values.hotlineSecondNumber.length < 4) {
        errors.hotlineSecondNumber = PHONE_TOO_SHORT_ERROR;
      } else if (values.hotlineSecondNumber.length > 30) {
        errors.hotlineSecondNumber = PHONE_TOO_LONG_ERROR;
      }
    }
  }

  // teleconsultation
  if (values.hasTeleconsultation) {
    if (
      values.teleconsultationNumber === "" &&
      values.teleconsultationWebsite === ""
    ) {
      errors.teleconsultationNumber = "Enter phone number";
      errors.teleconsultationWebsite = "Enter website URL";
    }
    if (values.teleconsultationNumber) {
      if (values.teleconsultationNumber.length < 4) {
        errors.teleconsultationNumber = PHONE_TOO_SHORT_ERROR;
      } else if (values.teleconsultationNumber.length > 30) {
        errors.teleconsultationNumber = PHONE_TOO_LONG_ERROR;
      }
    }
    if (values.teleconsultationWebsite) {
      if (values.teleconsultationWebsite.length < 4) {
        errors.teleconsultationWebsite = URL_TOO_SHORT_ERROR;
      } else if (values.teleconsultationWebsite.length > 512) {
        errors.teleconsultationWebsite = URL_TOO_LONG_ERROR;
      }
    }
  }

  // healthcareWebsite
  if (values.hasHealthcareWebsite) {
    if (values.healthcareWebsite === "") {
      errors.healthcareWebsite = "Enter website URL";
    } else if (values.healthcareWebsite.length < 4) {
      errors.healthcareWebsite = URL_TOO_SHORT_ERROR;
    } else if (values.healthcareWebsite.length > 512) {
      errors.healthcareWebsite = URL_TOO_LONG_ERROR;
    }
  }

  // doctorChat
  if (values.hasDoctorChat) {
    if (values.doctorChatPhysicianCountry === "") {
      errors.doctorChatPhysicianCountry = "Select country from a list";
    }
    if (values.doctorChatAvailableLanguages.length === 0) {
      errors.doctorChatAvailableLanguages = "Select at least 1 language";
    }
    if (values.doctorChatPartnerName === "") {
      errors.doctorChatPartnerName = "Provide ABI partner name";
    } else if (values.doctorChatPartnerName.length < 4) {
      errors.doctorChatPartnerName = NAME_TOO_SHORT_ERROR;
    } else if (values.doctorChatPartnerName.length > 100) {
      errors.doctorChatPartnerName = NAME_TOO_LONG_ERROR;
    }
  }
  return errors;
}

export default validateForm;
