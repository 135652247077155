import PropTypes from "prop-types";

import { Button } from "@medi24-da2c/web-ui";
import { INITIAL_STEP } from "constants/steps";
import BasicStep from "../BasicStep";

function ErrorStep({ setStep }) {
  return (
    <BasicStep
      headline="Error!"
      controls={
        <Button onClick={() => setStep(INITIAL_STEP)}>Try again</Button>
      }
    >
      <BasicStep.Text>
        Ooops! Something went wrong. Please try again.
      </BasicStep.Text>
    </BasicStep>
  );
}

ErrorStep.propTypes = {
  setStep: PropTypes.func.isRequired,
};

export default ErrorStep;
