import styled from "@emotion/styled";

import { spacingMedium } from "@medi24-da2c/web-ui/design-tokens";
import { CARD_LONG } from "./variants";

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.general.bgrColor};
  width: 40rem;
  min-height: ${(props) => (props.variant === CARD_LONG ? 82 : 40)}rem;
  border-radius: 0.8rem;
  padding: ${spacingMedium};
  margin-right: ${spacingMedium};
  margin-bottom: ${spacingMedium};
`;

export default Wrapper;
