import { useState } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";

import { ErrorBoundary, Button } from "@medi24-da2c/web-ui";
import { SUCCESS_STEP, ERROR_STEP } from "constants/steps";
import { deactivateActivationCodes } from "api/activationCodes";
import BasicForm from "../BasicForm";
import FormInput from "../FormInput";
import Tip from "./Tip";
import validateForm from "./validateForm";
import splitCodes from "./splitCodes";

const MAX_NUMBER_OF_CODES_TO_DEACTIVATE = 1000;

function InitialStep({ setStep, setNumberOfDeactivatedCodes }) {
  const [loading, setLoading] = useState(false);

  async function handleSubmit(values) {
    setLoading(true);
    setNumberOfDeactivatedCodes(0);

    const codes = splitCodes(values.activationCodes);

    try {
      const numberOfCodes = await deactivateActivationCodes(codes);
      setLoading(false);
      setNumberOfDeactivatedCodes(numberOfCodes);
      setStep(SUCCESS_STEP);
    } catch {
      setLoading(false);
      setStep(ERROR_STEP);
    }
  }

  return (
    <ErrorBoundary>
      <Formik
        initialValues={{
          activationCodes: "",
        }}
        validate={(values) =>
          validateForm(values, MAX_NUMBER_OF_CODES_TO_DEACTIVATE)
        }
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, dirty }) => (
          <BasicForm onSubmit={handleSubmit} noValidate>
            <BasicForm.Fields>
              <FormInput
                name="activationCodes"
                type="text"
                label="Enter code(s) to deactivate"
                spellCheck="false"
                autoComplete="off"
                placeholder="eg. acm123123, acm456456"
              />
              <Tip>
                For multiple codes just separate them with comma. Batch of
                maximum {MAX_NUMBER_OF_CODES_TO_DEACTIVATE} codes is allowed.
              </Tip>
            </BasicForm.Fields>
            {loading && <BasicForm.Loader />}
            <Button
              type="submit"
              disabled={!dirty}
              data-testid="deactivate-codes-button"
            >
              Deactivate code(s)
            </Button>
          </BasicForm>
        )}
      </Formik>
    </ErrorBoundary>
  );
}

InitialStep.propTypes = {
  setStep: PropTypes.func.isRequired,
  setNumberOfDeactivatedCodes: PropTypes.func.isRequired,
};

export default InitialStep;
