function displayDurationTime(timeInSeconds) {
  let time = "";
  let timeLeft = timeInSeconds;

  if (timeLeft / 3600 >= 1) {
    time += Math.floor(timeLeft / 3600).toString() + "h ";
    timeLeft = timeLeft % 3600;
  }

  if (timeLeft / 60 >= 1) {
    time += Math.floor(timeLeft / 60).toString() + "m ";
    timeLeft = timeLeft % 60;
  }

  return (time += Math.floor(timeLeft).toString() + "s");
}

export default displayDurationTime;
