import styled from "@emotion/styled";

import { Text } from "@medi24-da2c/web-ui";
import {
  spacingSmall,
  borderRadius,
  fontWeightBold,
} from "@medi24-da2c/web-ui/design-tokens";

const STATUS_ENABLED_COLOR = "#4c9b8a";
const STATUS_DISABLED_COLOR = "#e83363";

const StatusLabel = styled(Text)`
  display: inline-block;
  width: 10rem;
  text-align: center;
  background: ${(props) =>
    props.enabled ? STATUS_ENABLED_COLOR : STATUS_DISABLED_COLOR};
  border-radius: ${borderRadius};
  margin: 0;
  padding: 0 ${spacingSmall};
  text-transform: uppercase;
  font-weight: ${fontWeightBold};
  color: ${(props) => props.theme.general.bgrColor};
  line-height: 1.4;
`;

export default StatusLabel;
