function Chevron() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 9">
      <path
        fill="currentColor"
        d="M7 8.561L.293 1.854 1.707.44 7 5.732 12.293.439l1.414 1.414L7 8.561z"
      />
    </svg>
  );
}

export default Chevron;
