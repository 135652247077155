import styled from "@emotion/styled";

import { Loader as LoaderBase } from "@medi24-da2c/web-ui";
import { zIndexDefault } from "@medi24-da2c/web-ui/design-tokens";

const Loader = styled(LoaderBase)`
  position: absolute;
  bottom: 6rem;
  right: 0;
  z-index: ${zIndexDefault};
`;

export default Loader;
