import { ErrorBoundary, Headline } from "@medi24-da2c/web-ui";
import { Sessions } from "components/RatingsAndSessions";

function SessionsPage() {
  return (
    <ErrorBoundary>
      <Headline>Sessions</Headline>
      <Sessions />
    </ErrorBoundary>
  );
}

export default SessionsPage;
