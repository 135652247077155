import { COUNTRY_CODES } from "constants/countryCodes";
import countryCodeToName from "utils/countryCodeToName";

const all_countries = Object.keys(COUNTRY_CODES);

function mapCountryOptions(country_codes) {
  if (!country_codes) {
    country_codes = all_countries;
  }
  return country_codes
    .map((code) => {
      return {
        value: code,
        label: countryCodeToName(code),
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
}

export default mapCountryOptions;
