import styled from "@emotion/styled";

import { zIndexDefault } from "@medi24-da2c/web-ui/design-tokens";
import { Chevron } from "../Icons";

const Wrapper = styled.div`
  position: absolute;
  bottom: 3.4rem;
  right: 1rem;
  width: 1.4rem;
  color: ${(props) =>
    props.disabled
      ? props.theme.form.neutralColor
      : props.theme.form.inputTextColor};
  z-index: ${zIndexDefault};
  pointer-events: none;
`;

function Icon(props) {
  return (
    <Wrapper {...props}>
      <Chevron />
    </Wrapper>
  );
}

export default Icon;
