import fetchData from "./fetchData";

export async function createActivationCodes(
  partnerId,
  numberOfCodes,
  preGenerated
) {
  const codesDetails = await fetchData("/activation-codes/batches/sync", {
    method: "POST",
    body: { partnerId, numberOfCodes, preGenerated },
  });
  return codesDetails;
}

export async function getActivationCodesBatch(batchId) {
  const activationCodesBatch = await fetchData(
    `/activation-codes/batches/${batchId}.csv`,
    {
      method: "GET",
      responseType: "blob",
    }
  );
  return activationCodesBatch;
}

export async function deactivateActivationCodes(activationCodes) {
  const { numberOfActivationCodes } = await fetchData(
    "/activation-codes/invalidated",
    {
      method: "POST",
      body: {
        activationCodes,
      },
    }
  );
  return numberOfActivationCodes;
}
