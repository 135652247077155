import PropTypes from "prop-types";
import { FieldArray } from "formik";
import styled from "@emotion/styled";

import { Text, FormCheckbox } from "@medi24-da2c/web-ui";
import {
  fontWeightBold,
  fontFormSmall,
  spacingSmall,
  zIndexDefault,
  borderWidthThin,
} from "@medi24-da2c/web-ui/design-tokens";
import { useGetAbiLanguages } from "../../api/abiLanguages";
import doctorChatLanguages from "./doctorChatLanguages";

const Description = styled(Text)`
  font-size: ${fontFormSmall};
  font-weight: ${fontWeightBold};
  margin-top: 0;
  margin-bottom: ${spacingSmall};
`;

const Checkboxes = styled.div`
  position: relative;
  display: grid;
  white-space: nowrap;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${spacingSmall};
  margin-bottom: ${spacingSmall};
`;

const ErrorMessage = styled(Text)`
  position: absolute;
  bottom: -0.8rem;
  right: 0;
  margin: 0;
  z-index: ${zIndexDefault};
  color: ${(props) => props.theme.general.errorColor};
  font-size: ${fontFormSmall};
  border-top: ${borderWidthThin} solid
    ${(props) => props.theme.general.errorColor};
  width: 100%;
  text-align: right;
`;

function AvailableLanguages({ values, errors, touched }) {
  const abiLanguagesFromBackend = useGetAbiLanguages();
  return (
    <>
      <Description>Select languages available in doctor chat</Description>
      <Checkboxes data-testid="doctor-chat-available-languages">
        <FieldArray name="doctorChatAvailableLanguages">
          {({ push, remove }) =>
            doctorChatLanguages(abiLanguagesFromBackend).map((language) => (
              <FormCheckbox
                key={`doctor-chat-lang-${language.value}`}
                name="doctorChatAvailableLanguages"
                value={language.value}
                checked={values.doctorChatAvailableLanguages.includes(
                  language.value
                )}
                onChange={(event) => {
                  if (event.target.checked) {
                    push(language.value);
                  } else {
                    const languageIndex =
                      values.doctorChatAvailableLanguages.indexOf(
                        language.value
                      );
                    remove(languageIndex);
                  }
                }}
              >
                {language.label}
              </FormCheckbox>
            ))
          }
        </FieldArray>
        {touched.doctorChatAvailableLanguages &&
          errors.doctorChatAvailableLanguages && (
            <ErrorMessage>{errors.doctorChatAvailableLanguages}</ErrorMessage>
          )}
      </Checkboxes>
    </>
  );
}

AvailableLanguages.propTypes = {
  values: PropTypes.shape({
    doctorChatAvailableLanguages: PropTypes.array.isRequired,
  }).isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
};

export default AvailableLanguages;
