import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { Button as ButtonBase } from "@medi24-da2c/web-ui";
import { spacingSmall } from "@medi24-da2c/web-ui/design-tokens";
import { Cross } from "../Icons";

const Button = styled(ButtonBase)`
  width: 6rem;
  min-width: 6rem;
  margin-left: ${spacingSmall};
`;

const Icon = styled.span`
  width: 1.8rem;
  height: 1.8rem;
`;

function CloseButton({ onClick }) {
  return (
    <Button aria-label="Close" onClick={onClick}>
      <Icon>
        <Cross />
      </Icon>
    </Button>
  );
}

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CloseButton;
