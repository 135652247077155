function normalizedPartner(partnerWithProduct) {
  const {
    id,
    code,
    qrCodeUrl,
    name,
    contactEmail,
    supportEmail,
    privacyEmail,
    country,
    defaultLanguage,
    debugMode,
    botName,
    partnerSubdomain,
    logoutUrl,
    partnerCode,
    qrCodeType,
    landingPageUrl,
  } = partnerWithProduct.partner;
  const product = partnerWithProduct.product;

  var normalizedPartner = {
    id,
    code,
    qrCodeUrl,
    name,
    contactEmail,
    supportEmail,
    privacyEmail,
    country,
    defaultLanguage,
    debugMode,
    botName,
    partnerSubdomain,
    logoutUrl,
    partnerCode,
    qrCodeType,
    landingPageUrl,
    productId: product.id,
    hasHotline: product.hotline.enabled,
    hotlineNumber: product.hotline.number,
    hotlineSecondNumber: product.hotline.secondNumber,
    hasHealthcareWebsite: product.website.enabled,
    healthcareWebsite: product.website.link,
    hasSymptomChecker: product.symptomChecker.enabled,
    hasDoctorChat: product.doctorChat.enabled,
    doctorChatPartnerName: product.doctorChat.partnerName,
    doctorChatPhysicianCountry: product.doctorChat.physicianCountry,
    doctorChatAvailableLanguages: product.doctorChat.availableLanguages,
    hasTeleconsultation: product.teleconsultation.enabled,
    teleconsultationNumber: product.teleconsultation.number,
    teleconsultationWebsite: product.teleconsultation.link,
    teleconsultationCountry: product.teleconsultation.country,
    teleconsultationMode: product.teleconsultation.mode,
    teleconsultationMyDocGroupId: product.teleconsultation.myDocGroupId,
    teleconsultationMyDocClientId: product.teleconsultation.myDocClientId,
    teleconsultationMyDocClientSecret:
      product.teleconsultation.myDocClientSecret,
    teleconsultationMyDocGetRegistrationFieldsUrl:
      product.teleconsultation.myDocGetRegistrationFieldsUrl,
    teleconsultationMyDocCreateInvitationUrl:
      product.teleconsultation.myDocCreateInvitationUrl,
    hasSelfRegistration: product.selfRegistration.enabled,
    selfRegistrationEligibilityCheckType:
      product.selfRegistration.eligibilityCheckType,
    selfRegistrationMaxNumberOfActivationCodesPerUserKey:
      product.selfRegistration.maxNumberOfActivationCodesPerUserKey,
    selfRegistrationResetLeftActivationCodesDateType:
      product.selfRegistration.resetLeftActivationCodes
        .resetLeftActivationCodesDateType,
    selfRegistrationResetLeftActivationCodesNumberOfDays:
      product.selfRegistration.resetLeftActivationCodes.numberOfDays,
    selfRegistrationResetLeftActivationCodesDate:
      product.selfRegistration.resetLeftActivationCodes.date,
    selfRegistrationExpiryDateType: product.selfRegistration.expiryDateType,
    selfRegistrationNumberOfDays: product.selfRegistration.numberOfDays,
    selfRegistrationDate: product.selfRegistration.date,
    hasSelfRegistrationCredentialsImporter:
      product.selfRegistration.credentialsImporter.enabled,
    selfRegistrationCredentialsImporterSftpServer:
      product.selfRegistration.credentialsImporter.sftpServer,
    selfRegistrationCredentialsImporterSftpPort:
      product.selfRegistration.credentialsImporter.sftpPort,
    selfRegistrationCredentialsImporterSftpUsername:
      product.selfRegistration.credentialsImporter.sftpUsername,
    selfRegistrationCredentialsImporterSftpPassword:
      product.selfRegistration.credentialsImporter.sftpPassword,
    selfRegistrationCredentialsImporterSftpDirectoryPath:
      product.selfRegistration.credentialsImporter.sftpDirectoryPath,
    selfRegistrationCredentialsImporterSftpFile:
      product.selfRegistration.credentialsImporter.sftpFile,
    selfRegistrationCredentialsImporterColumns:
      product.selfRegistration.credentialsImporter.columns,
    selfRegistrationCredentialsImporterSkipFirstLine:
      product.selfRegistration.credentialsImporter.skipFirstLine,
  };
  return removeEmpty(normalizedPartner);
}

function removeEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

export default normalizedPartner;
