import { useState } from "react";
import PropTypes from "prop-types";
import { INITIAL_STEP, SUCCESS_STEP, SUCCESS_STEP_ALL } from "constants/steps";
import Card, { CARD_REGULAR } from "../Card";
import ErrorStep from "../ErrorStep";
import InitialStep from "./InitialStep";
import SuccessStep from "./SuccessStep";
import SuccessStepAll from "./SuccessStepAll";

function CheckUsageStatistics() {
  const [step, setStep] = useState(INITIAL_STEP);
  const [partnerDetails, setPartnerDetails] = useState(null);

  function renderContent() {
    if (step === INITIAL_STEP) {
      return (
        <InitialStep setStep={setStep} setPartnerDetails={setPartnerDetails} />
      );
    } else if (step === SUCCESS_STEP && partnerDetails) {
      return <SuccessStep setStep={setStep} partnerDetails={partnerDetails} />;
    } else if (step === SUCCESS_STEP_ALL) {
      return <SuccessStepAll setStep={setStep} />;
    }

    return <ErrorStep setStep={setStep} />;
  }

  return (
    <Card
      title="Check statistics of activation codes usage"
      variant={CARD_REGULAR}
    >
      {renderContent()}
    </Card>
  );
}

CheckUsageStatistics.propTypes = {
  range: PropTypes.array,
};

export default CheckUsageStatistics;
