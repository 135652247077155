function csvFileName(partnerName, partnerCode, timestamp) {
  const date = new Date(timestamp);
  const formattedDate = `${date.getDate()}_${
    date.getMonth() + 1
  }_${date.getFullYear()}_${date.getHours()}_${date.getMinutes()}`;
  const formattedName = partnerName.split(" ").join("_").toLowerCase();

  return `stats_${formattedName}[${partnerCode}]_${formattedDate}.csv`;
}

export default csvFileName;
