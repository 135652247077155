import PropTypes from "prop-types";

import ExistingPartnerSelectForm from "../ExistingPartnerSelectForm";
import { IN_PROGRESS_STEP } from "../../constants/steps";

function InitialStep({ setStep, setPartnerDetails }) {
  return (
    <ExistingPartnerSelectForm
      setStep={setStep}
      setPartnerDetails={setPartnerDetails}
      submitButtonLabel="Select partner to update"
      nextStep={IN_PROGRESS_STEP}
    />
  );
}

InitialStep.propTypes = {
  setStep: PropTypes.func.isRequired,
  setPartnerDetails: PropTypes.func.isRequired,
};

export default InitialStep;
