import splitCodes from "./splitCodes";

function validateForm(values, maxNumberOfCodes) {
  let errors = {};

  const { activationCodes } = values;

  if (activationCodes === "") {
    errors.activationCodes = "Enter activation code(s)";
  } else {
    // 1. Check wheter string is not crazy long to avoid unnecessary computations
    // 2. Split codes into array, ignoring whitespaces
    // 3. Check if number of codes for one batch is not exceeded
    // 4. Validate each code

    if (activationCodes.length > 100000) {
      errors.activationCodes = "List of codes is too long";
      return errors;
    }

    const codes = splitCodes(activationCodes);

    if (codes.length > maxNumberOfCodes) {
      errors.activationCodes = `Number of maximum codes (${maxNumberOfCodes}) exceeded`;
      return errors;
    }

    for (let i = 0; i < codes.length; i++) {
      const code = codes[i];
      const isValid = /^[a-zA-Z0-9]{9}$/g.test(code);

      if (!isValid) {
        errors.activationCodes = "Enter correct code(s)";
        return errors;
      }
    }
  }

  return errors;
}

export default validateForm;
