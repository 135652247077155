import styled from "@emotion/styled";

import { spacingMedium } from "@medi24-da2c/web-ui/design-tokens";

const Content = styled.div`
  flex: 1;
  padding-bottom: ${spacingMedium};
`;

export default Content;
