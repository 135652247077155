import { useState } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";

import { ErrorBoundary, Button } from "@medi24-da2c/web-ui";
import { SUCCESS_STEP, ERROR_STEP } from "constants/steps";
import { createPartner, useInvalidatePartners } from "api/partners";
import BasicForm from "../BasicForm";
import FormInput from "../FormInput";
import FormSelect from "../FormSelect";
import FormCheckbox from "../FormCheckbox";
import validateForm from "../AddUpdatePartner/validateForm";
import AvailableLanguages from "../AddUpdatePartner/AvailableLanguages";
import countryOptions from "../AddUpdatePartner/countryOptions";
import defaultLanguageOptions from "../AddUpdatePartner/defaultLanguageOptions";
import physicianCountryOptions from "../AddUpdatePartner/physicianCountryOptions";
import botNameOptions from "../AddUpdatePartner/botNameOptions";
import qrCodeTypeOptions from "../AddUpdatePartner/qrCodeTypeOptions";
import debugModeOptions from "../AddUpdatePartner/debugModeOptions";
import teleconsultationModeOptions from "../AddUpdatePartner/teleconsulationModeOptions";
import eligibilityCheckTypeOptions from "../AddUpdatePartner/eligibilityCheckTypeOptions";
import expiryDateTypeOptions from "../AddUpdatePartner/expiryDateTypeOptions";
import selfRegistrationResetLeftActivationCodesDateTypeOptions from "../AddUpdatePartner/selfRegistrationResetLeftActivationCodesDateTypeOptions";
import { useGetAbiCountries } from "../../api/abiCountries";

function InitialStep({ setStep, setPartnerDetails }) {
  const [loading, setLoading] = useState(false);
  const invalidatePartners = useInvalidatePartners();

  async function handleSubmit(values) {
    const newPartner = values;
    setLoading(true);
    setPartnerDetails(null);

    try {
      const partnerDetails = await createPartner(newPartner);
      setLoading(false);
      setPartnerDetails(partnerDetails);
      setStep(SUCCESS_STEP);
      // Invalidate partners cache to refetch it for list of existing partners
      invalidatePartners();
    } catch {
      setLoading(false);
      setStep(ERROR_STEP);
    }
  }

  const abiCountriesFromBackend = useGetAbiCountries();

  return (
    <ErrorBoundary>
      <Formik
        initialValues={{
          name: "",
          partnerCode: "",
          qrCodeType: "",
          contactEmail: "",
          supportEmail: "",
          privacyEmail: "",
          country: "",
          defaultLanguage: "",
          debugMode: "",
          botName: "",
          partnerSubdomain: "",
          logoutUrl: "",
          landingPageUrl: "",
          hasHotline: false,
          hotlineNumber: "",
          hasTeleconsultation: false,
          teleconsultationNumber: "",
          teleconsultationWebsite: "",
          teleconsultationCountry: "",
          teleconsultationMode: "",
          teleconsultationMyDocGroupId: "",
          teleconsultationMyDocGetRegistrationFieldsUrl: "",
          teleconsultationMyDocCreateInvitationUrl: "",
          teleconsultationMyDocClientId: "",
          teleconsultationMyDocClientSecret: "",
          hotlineSecondNumber: "",
          hasHealthcareWebsite: false,
          healthcareWebsite: "",
          hasSymptomChecker: false,
          hasDoctorChat: false,
          doctorChatPartnerName: "",
          doctorChatAvailableLanguages: [],
          doctorChatPhysicianCountry: "",
          hasSelfRegistration: false,
          selfRegistrationEligibilityCheckType: "",
          selfRegistrationMaxNumberOfActivationCodesPerUserKey: "",
          selfRegistrationExpiryDateType: "",
          selfRegistrationDate: "",
          selfRegistrationNumberOfDays: "",
        }}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, dirty, values, errors, touched }) => (
          <BasicForm onSubmit={handleSubmit} noValidate>
            <BasicForm.Fields>
              <BasicForm.FieldsLegend>General info</BasicForm.FieldsLegend>
              <FormInput
                name="name"
                type="text"
                label="Enter a new partner name"
                spellCheck="false"
                autoComplete="chrome-off"
              />
              <FormInput
                name="partnerCode"
                type="text"
                label="Enter a new partner code"
                spellCheck="false"
                autoComplete="chrome-off"
              />
              <FormSelect
                name="qrCodeType"
                label="Select qr code type"
                options={qrCodeTypeOptions}
              />
              <FormInput
                name="contactEmail"
                type="email"
                label="Enter a contact email to the new partner"
                autoComplete="chrome-off"
                spellCheck="false"
              />
              <FormInput
                name="supportEmail"
                type="email"
                label="Enter a support email to the new partner"
                autoComplete="chrome-off"
                spellCheck="false"
              />
              <FormInput
                name="privacyEmail"
                type="email"
                label="Enter a privacy email to the new partner"
                autoComplete="chrome-off"
                spellCheck="false"
              />
              <FormSelect
                name="country"
                label="Select partner's country"
                options={countryOptions}
              />
              <FormSelect
                name="defaultLanguage"
                label="Select partner's default language"
                options={defaultLanguageOptions}
              />
              <FormSelect
                name="debugMode"
                label="Select partner's debug mode"
                options={debugModeOptions}
              />
              <FormSelect
                name="botName"
                label="Select partner's bot name"
                options={botNameOptions}
              />
              <FormInput
                name="partnerSubdomain"
                type="text"
                label="Enter a partner subdomain (optional)"
                spellCheck="false"
                autoComplete="chrome-off"
              />
              <FormInput
                name="landingPageUrl"
                type="text"
                label="Enter a landing page URL"
                spellCheck="false"
                autoComplete="chrome-off"
              />
              <FormInput
                name="logoutUrl"
                type="text"
                label="Enter a logout URL"
                spellCheck="false"
                autoComplete="chrome-off"
              />
              <BasicForm.FieldsLegend>
                Additional services
              </BasicForm.FieldsLegend>
              {/* Hotline */}
              <FormCheckbox name="hasHotline">Hotline</FormCheckbox>
              {values.hasHotline && (
                <>
                  <FormInput
                    name="hotlineNumber"
                    type="tel"
                    label="Enter partner's hotline phone number"
                    spellCheck="false"
                    autoComplete="chrome-off"
                    placeholder="eg. +41 500 400 200"
                  />
                  <FormInput
                    name="hotlineSecondNumber"
                    type="tel"
                    label="Enter 2nd optional phone number"
                    spellCheck="false"
                    autoComplete="chrome-off"
                    placeholder="eg. +41 500 400 200"
                  />
                </>
              )}
              {/* Teleconsultation */}
              <FormCheckbox name="hasTeleconsultation">
                Teleconsultation
              </FormCheckbox>
              {values.hasTeleconsultation && (
                <>
                  <FormInput
                    name="teleconsultationNumber"
                    type="tel"
                    label="Enter teleconsultation phone number"
                    spellCheck="false"
                    autoComplete="chrome-off"
                    placeholder="eg. +41 500 400 200"
                  />
                  <FormInput
                    name="teleconsultationWebsite"
                    type="url"
                    label="Add teleconsultation website URL"
                    spellCheck="false"
                    autoComplete="chrome-off"
                    placeholder="eg. www.my-clicnic.de"
                  />
                  <FormSelect
                    name="teleconsultationCountry"
                    label="Select teleconsultation country"
                    options={countryOptions}
                  />
                  <FormSelect
                    name="teleconsultationMode"
                    label="Select teleconsultation mode"
                    options={teleconsultationModeOptions}
                  />
                  {values.teleconsultationMode === "MYDOC" && (
                    <>
                      <FormInput
                        name="teleconsultationMyDocGroupId"
                        type="text"
                        label="Enter a MyDoc's Group id"
                        spellCheck="false"
                        autoComplete="chrome-off"
                      />
                      <FormInput
                        name="teleconsultationMyDocGetRegistrationFieldsUrl"
                        type="text"
                        label="Enter a MyDoc's Get Registration Fields URL"
                        spellCheck="false"
                        autoComplete="chrome-off"
                      />
                      <FormInput
                        name="teleconsultationMyDocCreateInvitationUrl"
                        type="text"
                        label="Enter a MyDoc's Create Invitation URL"
                        spellCheck="false"
                        autoComplete="chrome-off"
                      />
                      <FormInput
                        name="teleconsultationMyDocClientId"
                        type="text"
                        label="Enter a MyDoc's Client ID"
                        spellCheck="false"
                        autoComplete="chrome-off"
                      />
                      <FormInput
                        name="teleconsultationMyDocClientSecret"
                        type="text"
                        label="Enter a MyDoc's Client Secret"
                        spellCheck="false"
                        autoComplete="chrome-off"
                      />
                    </>
                  )}
                </>
              )}
              {/* Healthcare provider website */}
              <FormCheckbox name="hasHealthcareWebsite">
                Healthcare provider's website URL
              </FormCheckbox>
              {values.hasHealthcareWebsite && (
                <FormInput
                  name="healthcareWebsite"
                  type="url"
                  label="Add URL to internal healthcare provider's website"
                  spellCheck="false"
                  autoComplete="chrome-off"
                  placeholder="eg. www.my-clicnic.de"
                />
              )}
              {/* Symptom checker */}
              <FormCheckbox name="hasSymptomChecker">
                Symptom checker – <strong>Sensely</strong>
              </FormCheckbox>
              {/* Doctor chat */}
              <FormCheckbox name="hasDoctorChat">
                Doctor chat – <strong>ABI</strong>
              </FormCheckbox>
              {values.hasDoctorChat && (
                <>
                  <FormInput
                    name="doctorChatPartnerName"
                    type="text"
                    label="Enter ABI partner name"
                    spellCheck="false"
                    autoComplete="chrome-off"
                    placeholder="eg. Emma Medi24"
                  />
                  <AvailableLanguages
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                  <FormSelect
                    name="doctorChatPhysicianCountry"
                    label="Select doctor chat's physician country"
                    options={physicianCountryOptions(abiCountriesFromBackend)}
                  />
                </>
              )}
              {/* Self registration */}
              <FormCheckbox name="hasSelfRegistration">
                Self registration
              </FormCheckbox>
              {values.hasSelfRegistration && (
                <>
                  <FormSelect
                    name="selfRegistrationEligibilityCheckType"
                    label="Select eligibility check type"
                    options={eligibilityCheckTypeOptions}
                  />
                  <FormInput
                    name="selfRegistrationMaxNumberOfActivationCodesPerUserKey"
                    type="text"
                    label="Enter maximum number of activation codes per credentials (0 = no limit)"
                    spellCheck="false"
                    autoComplete="chrome-off"
                  />
                  <FormSelect
                    name="selfRegistrationResetLeftActivationCodesDateType"
                    label="Select reset remaining left codes date type"
                    options={
                      selfRegistrationResetLeftActivationCodesDateTypeOptions
                    }
                  />
                  {values.selfRegistrationResetLeftActivationCodesDateType ===
                    "SPECIFIC_DATE" && (
                    <FormInput
                      name="selfRegistrationResetLeftActivationCodesDate"
                      type="text"
                      label="Enter specific date (format: YYYY-mm-dd)"
                      spellCheck="false"
                      autoComplete="chrome-off"
                    />
                  )}
                  {values.selfRegistrationResetLeftActivationCodesDateType ===
                    "NUMBER_OF_DAYS" && (
                    <FormInput
                      name="selfRegistrationResetLeftActivationCodesNumberOfDays"
                      type="text"
                      label="Enter number of days"
                      spellCheck="false"
                      autoComplete="chrome-off"
                    />
                  )}
                  <FormSelect
                    name="selfRegistrationExpiryDateType"
                    label="Select expiry date type"
                    options={expiryDateTypeOptions}
                  />
                  {values.selfRegistrationExpiryDateType ===
                    "SPECIFIC_DATE" && (
                    <FormInput
                      name="selfRegistrationDate"
                      type="text"
                      label="Enter specific date (format: YYYY-mm-dd)"
                      spellCheck="false"
                      autoComplete="chrome-off"
                    />
                  )}
                  {values.selfRegistrationExpiryDateType ===
                    "NUMBER_OF_DAYS" && (
                    <FormInput
                      name="selfRegistrationNumberOfDays"
                      type="text"
                      label="Enter number of days"
                      spellCheck="false"
                      autoComplete="chrome-off"
                    />
                  )}
                </>
              )}
            </BasicForm.Fields>
            {loading && <BasicForm.Loader />}
            <Button
              type="submit"
              disabled={!dirty || loading}
              data-testid="add-new-partner-button"
            >
              Add new partner
            </Button>
          </BasicForm>
        )}
      </Formik>
    </ErrorBoundary>
  );
}

InitialStep.propTypes = {
  setStep: PropTypes.func.isRequired,
  setPartnerDetails: PropTypes.func.isRequired,
};

export default InitialStep;
