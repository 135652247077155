import "sanitize.css";
import "sanitize.css/typography.css";

import { BrowserRouter } from "react-router-dom";
import { AuthenticationState } from "react-aad-msal";
import { ThemeProvider } from "@emotion/react";

import { GlobalStyle } from "@medi24-da2c/web-ui";
import { theme } from "@medi24-da2c/web-ui/default-theme";
import App from "../App";
import Authentication from "../Authentication";
import AuthMessage from "./AuthMessage";

function Root() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter>
        {process.env.REACT_APP_RUN_MODE === "no-auth" ? (
          <App data-testid="app" />
        ) : (
          <Authentication>
            {({ logout, authenticationState, error }) => {
              if (authenticationState === AuthenticationState.Authenticated) {
                return <App logout={logout} data-testid="app" />;
              } else if (
                authenticationState === AuthenticationState.Unauthenticated
              ) {
                return error ? (
                  <AuthMessage
                    headline="Error"
                    message="An error occured during authentication, please try again!"
                    data-testid="auth-error-message"
                  />
                ) : (
                  <AuthMessage
                    headline="Sorry, that seems you are not authenticated"
                    message="Please try again or make sure you have appropriate permissions"
                    data-testid="unauth-message"
                  />
                );
              }
              return (
                <AuthMessage
                  headline="Authenticating..."
                  message="Authentication in progress. Please wait a moment."
                  data-testid="in-progress-unauth-message"
                />
              );
            }}
          </Authentication>
        )}
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default Root;
