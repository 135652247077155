import { useQuery } from "react-query";
import fetchData from "./fetchData";

const ABI_COUNTRIES_QUERY_KEY = "abiCountries";

export async function getAbiCountries() {
  const abiCountries = await fetchData("/abiCountries", { method: "GET" });
  const normalizedAbiCountries = abiCountries.map(
    (abiCountry) => abiCountry.countryValue
  );
  return normalizedAbiCountries;
}

export function useGetAbiCountries() {
  const { data: abiCountries } = useQuery(
    ABI_COUNTRIES_QUERY_KEY,
    getAbiCountries
  );
  return abiCountries ? abiCountries : [];
}
