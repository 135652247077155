import PropTypes from "prop-types";

import { Button, Text } from "@medi24-da2c/web-ui";
import styled from "@emotion/styled";
import {
  fontFormMedium,
  fontWeightBold,
} from "@medi24-da2c/web-ui/design-tokens";
import { INITIAL_STEP } from "constants/steps";
import countryCodeToName from "utils/countryCodeToName";
import languageCodeToName from "utils/languageCodeToName";
import formatAvailableLanguages from "utils/formatAvailableLanguages";
import { botCodeToName } from "../../utils/botCodeToName";
import { switchModeToLabel } from "../../utils/switchModeToLabel";
import { qrCodeTypesToLabel } from "../../utils/qrCodeTypesToLabel";
import { teleconsultationModeToLabel } from "../../utils/teleconsultationModeToLabel";
import { eligibilityCheckTypeToLabel } from "../../utils/eligibilityCheckTypeToLabel";
import { expiryDateTypeToLabel } from "../../utils/expiryDateTypeToLabel";
import { selfRegistrationResetLeftActivationCodesDateTypeToLabel } from "../../utils/selfRegistrationResetLeftActivationCodesDateTypeToLabel";
import BasicStep from "../BasicStep";
import DetailsSection from "./DetailsSection";
import ProductDetailsSection from "./ProductDetailsSection";
import Detail from "./Detail";

const Title = styled(Text)`
  font-size: ${fontFormMedium};
  font-weight: ${fontWeightBold};
  margin: 0;
`.withComponent("h4");

function SuccessStep({ setStep, partnerDetails }) {
  return (
    <BasicStep
      headline={partnerDetails.name}
      controls={
        <Button onClick={() => setStep(INITIAL_STEP)}>
          Check different partner
        </Button>
      }
    >
      {partnerDetails.qrCodeUrl && (
        <>
          <img
            src={partnerDetails.qrCodeUrl}
            width={200}
            height={200}
            alt={"qr code"}
          />
        </>
      )}

      {/* General */}
      <DetailsSection title="General info" data-testid="general-info-details">
        <Detail label="Name" value={partnerDetails.name} />
        <Detail
          label="URL"
          value={
            <a
              href={partnerDetails.landingPageUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {partnerDetails.landingPageUrl}
            </a>
          }
        />
        <Detail label="Partner code" value={partnerDetails.partnerCode} />
        <Detail label="Code" value={partnerDetails.code} />
        <Detail
          label="QR code type"
          value={qrCodeTypesToLabel(partnerDetails.qrCodeType)}
        />
        <Detail label="Contact email" value={partnerDetails.contactEmail} />
        <Detail label="Support email" value={partnerDetails.supportEmail} />
        <Detail label="Privacy email" value={partnerDetails.privacyEmail} />
        <Detail
          label="Country"
          value={countryCodeToName(partnerDetails.country)}
        />
        <Detail
          label="Language"
          value={languageCodeToName(partnerDetails.defaultLanguage)}
        />
        <Detail
          label="Debug mode"
          value={switchModeToLabel(partnerDetails.debugMode)}
        />
        <Detail
          label="Bot name"
          value={botCodeToName(partnerDetails.botName)}
        />
        <Detail
          label="Partner subdomain"
          value={partnerDetails.partnerSubdomain}
        />
        <Detail label="Logout URL" value={partnerDetails.logoutUrl} />
      </DetailsSection>
      {/* Hotline */}
      <ProductDetailsSection
        title="Hotline"
        enabled={partnerDetails.hasHotline}
        data-testid="hotline-details"
      >
        {partnerDetails.hasHotline && (
          <>
            <Detail label="Phone" value={partnerDetails.hotlineNumber} />
            <Detail
              label="2nd phone"
              value={partnerDetails.hotlineSecondNumber}
            />
          </>
        )}
      </ProductDetailsSection>
      {/* Teleconsultation */}
      <ProductDetailsSection
        title="Teleconsultation"
        enabled={partnerDetails.hasTeleconsultation}
        data-testid="teleconsultation-details"
      >
        {partnerDetails.hasTeleconsultation && (
          <>
            <Detail
              label="Phone"
              value={partnerDetails.teleconsultationNumber}
            />
            <Detail
              label="Website"
              value={partnerDetails.teleconsultationWebsite}
            />
            <Detail
              label="Country"
              value={countryCodeToName(partnerDetails.teleconsultationCountry)}
            />
            <Detail
              label="Mode"
              value={teleconsultationModeToLabel(
                partnerDetails.teleconsultationMode
              )}
            />
            {partnerDetails.teleconsultationMode === "MYDOC" && (
              <>
                <Title>Teleconsultation - MyDoc Configuration</Title>
                <Detail
                  label="Group Id"
                  value={partnerDetails.teleconsultationMyDocGroupId}
                />
                <Detail
                  label="Get Registration Fields URL"
                  value={
                    partnerDetails.teleconsultationMyDocGetRegistrationFieldsUrl
                  }
                />
                <Detail
                  label="Create Invitation URL"
                  value={
                    partnerDetails.teleconsultationMyDocCreateInvitationUrl
                  }
                />
                <Detail
                  label="Client ID"
                  value={partnerDetails.teleconsultationMyDocClientId}
                />
                <Detail
                  label="Client Secret"
                  value={partnerDetails.teleconsultationMyDocClientSecret}
                />
              </>
            )}
          </>
        )}
      </ProductDetailsSection>
      {/* Website */}
      <ProductDetailsSection
        title="Healthcare website"
        enabled={partnerDetails.hasHealthcareWebsite}
      >
        {partnerDetails.hasHealthcareWebsite && (
          <Detail label="Website" value={partnerDetails.healthcareWebsite} />
        )}
      </ProductDetailsSection>
      {/* Symptom checker */}
      <ProductDetailsSection
        title="Symptom checker"
        enabled={partnerDetails.hasSymptomChecker}
      />
      {/* Doctor chat */}
      <ProductDetailsSection
        title="Doctor chat"
        enabled={partnerDetails.hasDoctorChat}
        data-testid="doctor-chat-details"
      >
        {partnerDetails.hasDoctorChat && (
          <>
            <Detail
              label="ABI name"
              value={partnerDetails.doctorChatPartnerName}
            />
            <Detail
              label="Languages"
              value={
                partnerDetails.doctorChatAvailableLanguages &&
                formatAvailableLanguages(
                  partnerDetails.doctorChatAvailableLanguages
                )
              }
            />
            <Detail
              label="Physician"
              value={
                partnerDetails.doctorChatPhysicianCountry &&
                countryCodeToName(partnerDetails.doctorChatPhysicianCountry)
              }
            />
          </>
        )}
      </ProductDetailsSection>
      {/* Self registration */}
      <ProductDetailsSection
        title="Self registration"
        enabled={partnerDetails.hasSelfRegistration}
        data-testid="self-registration-details"
      >
        {partnerDetails.hasSelfRegistration && (
          <>
            <Detail
              label="Eligibility check type"
              value={eligibilityCheckTypeToLabel(
                partnerDetails.selfRegistrationEligibilityCheckType
              )}
            />
            <Detail
              label="Maximum number of activation codes per credentials"
              value={
                partnerDetails.selfRegistrationMaxNumberOfActivationCodesPerUserKey
              }
            />
            <Detail
              label="Reset left activation codes date type"
              value={selfRegistrationResetLeftActivationCodesDateTypeToLabel(
                partnerDetails.selfRegistrationResetLeftActivationCodesDateType
              )}
            />
            {partnerDetails.selfRegistrationResetLeftActivationCodesDateType ===
              "SPECIFIC_DATE" && (
              <Detail
                label="Reset left activation codes - date"
                value={
                  partnerDetails.selfRegistrationResetLeftActivationCodesDate
                }
              />
            )}
            {partnerDetails.selfRegistrationResetLeftActivationCodesDateType ===
              "NUMBER_OF_DAYS" && (
              <Detail
                label="Reset left activation codes - number of days"
                value={
                  partnerDetails.selfRegistrationResetLeftActivationCodesNumberOfDays
                }
              />
            )}
            <Detail
              label="Expiry date type"
              value={expiryDateTypeToLabel(
                partnerDetails.selfRegistrationExpiryDateType
              )}
            />
            {partnerDetails.selfRegistrationExpiryDateType ===
              "SPECIFIC_DATE" && (
              <Detail
                label="Expiry date - date"
                value={partnerDetails.selfRegistrationDate}
              />
            )}
            {partnerDetails.selfRegistrationExpiryDateType ===
              "NUMBER_OF_DAYS" && (
              <Detail
                label="Expiry - number of days"
                value={partnerDetails.selfRegistrationNumberOfDays}
              />
            )}
            <ProductDetailsSection
              title="Credentials importer"
              enabled={partnerDetails.hasSelfRegistrationCredentialsImporter}
            />
            {partnerDetails.hasSelfRegistrationCredentialsImporter && (
              <>
                <Detail
                  label="SFTP server"
                  value={
                    partnerDetails.selfRegistrationCredentialsImporterSftpServer
                  }
                />
                <Detail
                  label="SFTP port"
                  value={
                    partnerDetails.selfRegistrationCredentialsImporterSftpPort
                  }
                />
                <Detail
                  label="SFTP username"
                  value={
                    partnerDetails.selfRegistrationCredentialsImporterSftpUsername
                  }
                />
                <Detail label="SFTP password" value="***" />
                <Detail
                  label="Skip first line"
                  value={switchModeToLabel(
                    partnerDetails.selfRegistrationCredentialsImporterSkipFirstLine
                  )}
                />
                <Detail
                  label="SFTP directory path"
                  value={
                    partnerDetails.selfRegistrationCredentialsImporterSftpDirectoryPath
                  }
                />
                <Detail
                  label="SFTP file name"
                  value={
                    partnerDetails.selfRegistrationCredentialsImporterSftpFile
                  }
                />
                <Detail
                  label="CSV columns"
                  value={
                    partnerDetails.selfRegistrationCredentialsImporterColumns
                  }
                />
              </>
            )}
          </>
        )}
      </ProductDetailsSection>
    </BasicStep>
  );
}

SuccessStep.propTypes = {
  setStep: PropTypes.func.isRequired,
  partnerDetails: PropTypes.shape({
    name: PropTypes.string.isRequired,
    partnerCode: PropTypes.string,
    code: PropTypes.string.isRequired,
    contactEmail: PropTypes.string.isRequired,
    supportEmail: PropTypes.string,
    privacyEmail: PropTypes.string,
    country: PropTypes.string.isRequired,
    defaultLanguage: PropTypes.string.isRequired,
    debugMode: PropTypes.bool,
    botName: PropTypes.string,
    partnerSubdomain: PropTypes.string,
    logoutUrl: PropTypes.string,
    qrCodeType: PropTypes.string,
    hasHotline: PropTypes.bool.isRequired,
    hotlineNumber: PropTypes.string,
    hotlineSecondNumber: PropTypes.string,
    hasTeleconsultation: PropTypes.bool.isRequired,
    teleconsultationNumber: PropTypes.string,
    teleconsultationWebsite: PropTypes.string,
    teleconsultationCountry: PropTypes.string,
    teleconsultationMode: PropTypes.string,
    teleconsultationMyDocGroupId: PropTypes.string,
    teleconsultationMyDocCreateInvitationUrl: PropTypes.string,
    teleconsultationMyDocGetRegistrationFieldsUrl: PropTypes.string,
    teleconsultationMyDocClientId: PropTypes.string,
    teleconsultationMyDocClientSecret: PropTypes.string,
    hasHealthcareWebsite: PropTypes.bool.isRequired,
    healthcareWebsite: PropTypes.string,
    hasSymptomChecker: PropTypes.bool.isRequired,
    hasDoctorChat: PropTypes.bool.isRequired,
    doctorChatPartnerName: PropTypes.string,
    doctorChatPhysicianCountry: PropTypes.string,
    doctorChatAvailableLanguages: PropTypes.arrayOf(PropTypes.string),
    hasSelfRegistration: PropTypes.bool.isRequired,
    selfRegistrationEligibilityCheckType: PropTypes.string,
    selfRegistrationMaxNumberOfActivationCodesPerUserKey: PropTypes.number,
    selfRegistrationExpiryDateType: PropTypes.string,
    selfRegistrationNumberOfDays: PropTypes.number,
    selfRegistrationDate: PropTypes.string,
  }).isRequired,
};

export default SuccessStep;
