import styled from "@emotion/styled";

import { Text } from "@medi24-da2c/web-ui";
import {
  spacingSmall,
  fontWeightBold,
  fontFormMedium,
} from "@medi24-da2c/web-ui/design-tokens";

const FieldsLegend = styled(Text)`
  font-size: ${fontFormMedium};
  font-weight: ${fontWeightBold};
  margin-bottom: ${spacingSmall};

  &:first-of-type {
    margin-top: 0;
  }
`.withComponent("h3");

export default FieldsLegend;
