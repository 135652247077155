import { expiryDateTypeToLabel } from "../../utils/expiryDateTypeToLabel";

function mapExpiryDateTypeOptions(expiryDateTypes) {
  return expiryDateTypes.map((expiryDateType) => {
    return {
      value: expiryDateType,
      label: expiryDateTypeToLabel(expiryDateType),
    };
  });
}

export default mapExpiryDateTypeOptions;
