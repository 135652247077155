import { SWITCH_MODES } from "../../constants/switchModes";
import mapDebugModeOptions from "./mapDebugModeOptions";

const debugModeOptions = [
  {
    value: "",
    label: "Debug mode",
    disabled: true,
  },
].concat(mapDebugModeOptions(Object.keys(SWITCH_MODES)));

export default debugModeOptions;
