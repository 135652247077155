import SidebarLink from "../SidebarLink";
import { Chart, Handshake, Lock } from "../Icons";

function Navigation() {
  return (
    <span>
      <nav>
        <SidebarLink to="/" label="Statistics" icon={<Chart />} />
        <SidebarLink to="/partners" label="Partners" icon={<Handshake />} />
        <SidebarLink to="/codes" label="Codes" icon={<Lock />} />
      </nav>
      <br />
      <nav>
        <SidebarLink to="/metrics/ratings" label="Ratings" icon={<Chart />} />
        <SidebarLink to="/metrics/sessions" label="Sessions" icon={<Chart />} />
        {/*<SidebarLink*/}
        {/*to="/metrics/integrated-services"*/}
        {/*label="Services"*/}
        {/*icon={<Chart />}*/}
        {/*/>*/}
      </nav>
    </span>
  );
}

export default Navigation;
