import PropTypes from "prop-types";
import { useField } from "formik";

import { FormInput as Input } from "@medi24-da2c/web-ui";

function FormInput({ label, type, icon, children, ...props }) {
  const [field, meta] = useField(props);

  return (
    <Input
      {...field}
      {...props}
      label={label}
      type={type}
      icon={icon}
      error={meta.error}
      touched={meta.touched}
    >
      {children}
    </Input>
  );
}

FormInput.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.element,
  children: PropTypes.node,
};

export default FormInput;
