export const LANGUAGE_CODES = {
  ca: "Catalan",
  cs: "Czech",
  de: "German",
  en: "English",
  "en-AU": "English (Australia)",
  "en-IN": "English (India)",
  es: "Spanish",
  fr: "French",
  it: "Italian",
  pt: "Portuguese",
  ro: "Romanian",
  sl: "Slovenian",
  sk: "Slovak",
  th: "Thai",
  vn: "Vietnamese",
  "zh-CN": "Chinese",
  "zh-HK": "Chinese HK",
  "zh-SG": "Chinese SG",
  "zh-TW": "Chinese TW",
  "zh-Hant": "Chinese Hant",
};
