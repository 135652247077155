import PropTypes from "prop-types";

import { Button } from "@medi24-da2c/web-ui";
import BasicStep from "../BasicStep";
import Wrapper from "./Wrapper";

function DownloadCsvControls({ onDownloadButtonClick, onCloseButtonClick }) {
  return (
    <Wrapper>
      <Button onClick={onDownloadButtonClick} data-testid="download-csv-button">
        Download .CSV
      </Button>
      <BasicStep.CloseButton onClick={onCloseButtonClick} />
    </Wrapper>
  );
}

DownloadCsvControls.propTypes = {
  onDownloadButtonClick: PropTypes.func.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
};

export default DownloadCsvControls;
