import { BOT_NAMES } from "constants/botNames";
import mapBotNameOptions from "./mapBotNameOptions";

const botNameOptions = [
  {
    value: "",
    label: "Bot name",
    disabled: true,
  },
].concat(mapBotNameOptions(Object.keys(BOT_NAMES)));

export default botNameOptions;
