function Cross() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
      <path
        fill="currentColor"
        d="M2.54 19.064l6.987-6.986 6.986 6.987 2.475-2.475-6.987-6.987 6.987-6.986L16.513.142 9.527 7.128 2.541.142.065 2.617l6.987 6.986-6.986 6.986z"
      />
    </svg>
  );
}

export default Cross;
