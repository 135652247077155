import PropTypes from "prop-types";

import Wrapper from "./Wrapper";
import Title from "./Title";
import { CARD_REGULAR } from "./variants";

function Card({ title, variant = CARD_REGULAR, children }) {
  return (
    <Wrapper variant={variant}>
      <Title>{title}</Title>
      {children}
    </Wrapper>
  );
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  children: PropTypes.node,
};

export default Card;
