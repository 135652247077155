function validateForm(values) {
  let errors = {};

  if (values.existingPartnerId === "") {
    errors.existingPartnerId = "Select partner from a list";
  }

  return errors;
}

export default validateForm;
