import styled from "@emotion/styled";

import { Subheadline } from "@medi24-da2c/web-ui";

const Title = styled(Subheadline)`
  display: block;
  margin: 0;
  height: 7rem;
`;

export default Title;
