import mapCountryOptions from "./mapCountryOptions";

const countryOptions = [
  {
    value: "",
    label: "Country",
    disabled: true,
  },
].concat(mapCountryOptions());

export default countryOptions;
