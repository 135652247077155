import styled from "@emotion/styled";

import { spacingSmall } from "@medi24-da2c/web-ui/design-tokens";

const Icon = styled.div`
  width: 5.6rem;
  height: 4rem;
  padding-left: 1rem;
  margin-right: ${spacingSmall};
  color: ${(props) =>
    props.isActive
      ? props.theme.general.linkColor
      : props.theme.general.textColor};
  border-left: 0.6rem solid
    ${(props) =>
      props.isActive ? props.theme.general.linkColor : "transparent"};
  transition: all 150ms ease-out;
`;

export default Icon;
