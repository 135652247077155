import { SELF_REGISTRATION_RESET_LEFT_ACTIVATION_CODES_DATE_TYPES } from "../../constants/selfRegistrationResetLeftActivationCodesDateType";
import mapSelfRegistrationResetLeftActivationCodesDateTypeOptions from "./mapSelfRegistrationResetLeftActivationCodesDateTypeOptions";

const selfRegistrationResetLeftActivationCodesDateTypeOptions = [
  {
    value: "",
    label: "Reset remaining left codes date type",
    disabled: true,
  },
].concat(
  mapSelfRegistrationResetLeftActivationCodesDateTypeOptions(
    Object.keys(SELF_REGISTRATION_RESET_LEFT_ACTIVATION_CODES_DATE_TYPES)
  )
);

export default selfRegistrationResetLeftActivationCodesDateTypeOptions;
