import { useState } from "react";

import { IN_PROGRESS_STEP, INITIAL_STEP, SUCCESS_STEP } from "constants/steps";
import Card, { CARD_REGULAR } from "../Card";
import ErrorStep from "../ErrorStep";
import InitialStep from "./InitialStep";
import InProgressStep from "./InProgressStep";
import SuccessStep from "./SuccessStep";

function UpdatePartner() {
  const [step, setStep] = useState(INITIAL_STEP);
  const [partnerDetails, setPartnerDetails] = useState(null);

  function renderCardContent() {
    if (step === INITIAL_STEP) {
      return (
        <InitialStep setStep={setStep} setPartnerDetails={setPartnerDetails} />
      );
    } else if (step === IN_PROGRESS_STEP && partnerDetails) {
      return (
        <InProgressStep
          setStep={setStep}
          partnerDetails={partnerDetails}
          setPartnerDetails={setPartnerDetails}
        />
      );
    } else if (step === SUCCESS_STEP && partnerDetails) {
      return <SuccessStep setStep={setStep} partnerDetails={partnerDetails} />;
    }
    return <ErrorStep setStep={setStep} />;
  }

  return (
    <Card title="Update existing partner" variant={CARD_REGULAR}>
      {renderCardContent()}
    </Card>
  );
}

export default UpdatePartner;
