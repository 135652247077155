import mapCountryOptions from "./mapCountryOptions";

function physicianCountryOptions(abiCountriesFromBackend) {
  return [
    {
      value: "",
      label: "Physician's country",
      disabled: true,
    },
  ].concat(mapCountryOptions(abiCountriesFromBackend));
}

export default physicianCountryOptions;
