import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { Button, Text } from "@medi24-da2c/web-ui";
import {
  spacingMedium,
  borderWidthThin,
} from "@medi24-da2c/web-ui/design-tokens";
import { SUCCESS_STEP_ALL } from "constants/steps";

const Wrapper = styled.div`
  position: relative;
  margin-top: ${spacingMedium};
  padding-top: ${spacingMedium};
  border-top: ${borderWidthThin} solid
    ${(props) => props.theme.general.borderColor};
`;

const Decor = styled(Text)`
  position: absolute;
  top: -1.4rem;
  left: calc(50% - 2rem);
  margin: 0;
  text-align: center;
  background-color: ${(props) => props.theme.general.bgrColor};
  width: 4rem;
  height: 2rem;
`;

function GatherAllStats({ setStep }) {
  return (
    <Wrapper>
      <Decor as="div">or</Decor>
      <Button
        onClick={() => setStep(SUCCESS_STEP_ALL)}
        data-testid="get-all-partners-stats-button"
      >
        Get all partners statistics
      </Button>
    </Wrapper>
  );
}

GatherAllStats.propTypes = {
  setStep: PropTypes.func.isRequired,
};

export default GatherAllStats;
