import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";

import { ErrorBoundary, Button } from "@medi24-da2c/web-ui";
import { ERROR_STEP } from "constants/steps";
import { useGetPartners } from "api/partners";
import BasicForm from "../BasicForm";
import ExistingPartnersSelect from "../ExistingPartnersSelect";
import validateForm from "./validateForm";

function ExistingPartnerSelectForm({
  setStep,
  setPartnerDetails,
  submitButtonLabel,
  nextStep,
}) {
  const { partners, status } = useGetPartners();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Adds slight delay to prevent flickering in case of initial query errors
    const timeout = setTimeout(() => {
      if (status === "error") {
        setStep(ERROR_STEP);
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, [status, setStep]);

  useEffect(() => {
    setLoading(status === "loading");
  }, [status]);

  function handleSubmit(values) {
    const { existingPartnerId } = values;

    const partnerDetails = partners.find(
      (partner) => partner.id === existingPartnerId
    );

    setPartnerDetails(partnerDetails);
    setStep(nextStep);
  }

  return (
    <ErrorBoundary>
      <Formik
        initialValues={{
          existingPartnerId: "",
        }}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, dirty }) => (
          <BasicForm onSubmit={handleSubmit} noValidate>
            <BasicForm.Fields>
              <ExistingPartnersSelect partners={partners} />
            </BasicForm.Fields>
            {loading && <BasicForm.Loader />}
            <Button
              type="submit"
              disabled={!dirty}
              data-testid="existing-partner-submit-button"
            >
              {submitButtonLabel}
            </Button>
          </BasicForm>
        )}
      </Formik>
    </ErrorBoundary>
  );
}

ExistingPartnerSelectForm.propTypes = {
  setStep: PropTypes.func.isRequired,
  setPartnerDetails: PropTypes.func.isRequired,
  submitButtonLabel: PropTypes.string.isRequired,
};

export default ExistingPartnerSelectForm;
