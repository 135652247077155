import { qrCodeTypesToLabel } from "../../utils/qrCodeTypesToLabel";

function mapQrCodeTypeOptions(qrCodeTypes) {
  return qrCodeTypes.map((qrCodeType) => {
    return {
      value: qrCodeType,
      label: qrCodeTypesToLabel(qrCodeType),
    };
  });
}

export default mapQrCodeTypeOptions;
