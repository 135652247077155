import { eligibilityCheckTypeToLabel } from "../../utils/eligibilityCheckTypeToLabel";

function mapEligibilityCheckTypeOptions(eligibilityCheckTypes) {
  return eligibilityCheckTypes.map((eligibilityCheckType) => {
    return {
      value: eligibilityCheckType,
      label: eligibilityCheckTypeToLabel(eligibilityCheckType),
    };
  });
}

export default mapEligibilityCheckTypeOptions;
