import styled from "@emotion/styled";

import {
  fontWeightNormal,
  fontMedium,
  spacingMedium,
  spacingSmall,
} from "@medi24-da2c/web-ui/design-tokens";
import { colorAnthraciteMedium } from "@medi24-da2c/web-ui/default-theme";

const Headline = styled.h2`
  font-family: "Allianz Neo", sans-serif;
  color: ${colorAnthraciteMedium};
  font-size: ${fontMedium};
  font-weight: ${fontWeightNormal};
  line-height: 1;
  margin: 0;
  padding: 6.2rem 0 ${spacingSmall} ${spacingMedium};
`;

export default Headline;
