import styled from "@emotion/styled";

import { Link } from "@medi24-da2c/web-ui";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
`;

function LogOut({ logout }) {
  return (
    <Wrapper>
      <Link as="button" onClick={logout}>
        Log out
      </Link>
    </Wrapper>
  );
}

export default LogOut;
