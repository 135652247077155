import styled from "@emotion/styled";

const Label = styled.span`
  font-family: "Allianz Neo", sans-serif;
  color: ${(props) =>
    props.isActive
      ? props.theme.general.linkColor
      : props.theme.general.textColor};
  transition: all 150ms ease-out;
`;

export default Label;
