function createChartOptions(text, options) {
  return {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: text,
      },
    },
    ...options,
  };
}

export default createChartOptions;
