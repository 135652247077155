import { switchModeToLabel } from "../../utils/switchModeToLabel";

function mapDebugModeOptions(debugModes) {
  return debugModes.map((debugMode) => {
    return {
      value: debugMode,
      label: switchModeToLabel(debugMode),
    };
  });
}

export default mapDebugModeOptions;
