// Wrapper around fetch with base URL configured and auth token obtained
// Used to fetch data from real API

import axios from "axios";

import authProvider from "components/Authentication/authProvider";

const BASE_API_PATH = `${process.env.REACT_APP_API_HOST_URL}/partner-dashboard-service`;

async function fetchData(url, { method, body, responseType }) {
  const token = await authProvider.getIdToken();
  const authToken = token.idToken.rawIdToken;

  const { data } = await axios({
    method,
    url: `${BASE_API_PATH}${url}`,
    responseType,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    data: body,
    timeout: 600000,
  });

  return data;
}

export default fetchData;
