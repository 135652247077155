import styled from "@emotion/styled";

import { spacingLarge } from "@medi24-da2c/web-ui/design-tokens";

const Fields = styled.div`
  padding-bottom: ${spacingLarge};
  flex: 1;
`;

export default Fields;
