import { useQuery } from "react-query";
import fetchData from "./fetchData";

const RATINGS_ENDPOINT = "/sessions/ratings";
const RATINGS_DISTRIBUTION_ENDPOINT = "/sessions/ratings/user-distribution";

export async function callRatingsEndpoint(
  baseUri,
  startAtFrom,
  startAtTo,
  partnerId,
  period,
  excludeNamePattern
) {
  let queryString = `${baseUri}?startAtFrom=${startAtFrom}&startAtTo=${startAtTo}`;
  if (partnerId) {
    queryString += `&partnerId=${partnerId}`;
  }
  if (period) {
    queryString += `&period=${period}`;
  }
  if (excludeNamePattern) {
    queryString += `&excludeNamePattern=${excludeNamePattern}`;
  }
  const records = await fetchData(queryString, {
    method: "GET",
  });
  return records;
}

export async function getAllRatings(
  startAtFrom,
  startAtTo,
  partnerId,
  period,
  excludeNamePattern
) {
  return callRatingsEndpoint(
    RATINGS_ENDPOINT,
    startAtFrom,
    startAtTo,
    partnerId,
    period,
    excludeNamePattern
  );
}

export async function getRatingsDistribution(
  startAtFrom,
  startAtTo,
  partnerId,
  period,
  excludeNamePattern
) {
  return callRatingsEndpoint(
    RATINGS_DISTRIBUTION_ENDPOINT,
    startAtFrom,
    startAtTo,
    partnerId,
    period,
    excludeNamePattern
  );
}

export function useGetAllRatings(startDate, endDate) {
  const { data: ratings, status } = useQuery(RATINGS_ENDPOINT, () =>
    getAllRatings(startDate, endDate, null)
  );
  return { ratings, status };
}

export function useGetRatingsDistribution(startDate, endDate) {
  const { data: records, status } = useQuery(
    RATINGS_DISTRIBUTION_ENDPOINT,
    () => getRatingsDistribution(startDate, endDate, null)
  );
  return { records, status };
}
