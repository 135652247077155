import styled from "@emotion/styled";
import { Link as LinkBase } from "react-router-dom";

import {
  fontMedium,
  fontWeightNormal,
} from "@medi24-da2c/web-ui/design-tokens";

const Link = styled(LinkBase)`
  display: block;
  width: 100%;
  height: 4rem;
  text-decoration: none;
  font-size: ${fontMedium};
  font-weight: ${fontWeightNormal};
  outline: 0;
`;

export default Link;
