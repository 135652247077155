import { AzureAD } from "react-aad-msal";

import authProvider from "./authProvider";

function Authentication({ children }) {
  return (
    <AzureAD provider={authProvider} forceLogin={true}>
      {children}
    </AzureAD>
  );
}

export default Authentication;
