import styled from "@emotion/styled";

import { Text as TextBase } from "@medi24-da2c/web-ui";
import { fontSmall, spacingMedium } from "@medi24-da2c/web-ui/design-tokens";
import { colorAnthraciteMedium } from "@medi24-da2c/web-ui/default-theme";

const Text = styled(TextBase)`
  font-size: ${fontSmall};
  color: ${colorAnthraciteMedium};
  padding: 0 ${spacingMedium};
`;

function Copyright() {
  return <Text>&copy; Medi24 AG 2021. All rights reserved.</Text>;
}

export default Copyright;
