import { Routes, Route } from "react-router-dom";

import StatisticsPage from "pages/StatisticsPage";
import PartnersManagementPage from "pages/PartnersManagementPage";
import CodesManagementPage from "pages/CodesManagementPage";
import PageNotFound from "pages/PageNotFound";
import {
  SessionsPage,
  RatingsPage,
} from "pages/MetricsDashboard/SessionsAndRatings";
import AdminPartnersManagementPage from "../../pages/AdminPartnersManagementPage";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<StatisticsPage />} />
      <Route path="/partners" element={<PartnersManagementPage />} />
      <Route path="/partners-admin" element={<AdminPartnersManagementPage />} />
      <Route path="/codes" element={<CodesManagementPage />} />
      <Route path="/metrics/sessions" element={<SessionsPage />} />
      <Route path="/metrics/ratings" element={<RatingsPage />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default AppRoutes;
