import styled from "@emotion/styled";

import { Text } from "@medi24-da2c/web-ui";

const Tip = styled(Text)`
  font-style: italic;
  margin-top: 0;
`;

export default Tip;
