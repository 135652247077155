import PropTypes from "prop-types";
import { useField } from "formik";

import { FormCheckbox as Checkbox } from "@medi24-da2c/web-ui";

function FormCheckbox({ disabled, children, ...props }) {
  const [field] = useField(props);

  // So far all Formik checkboxes' values are either true or false
  // Therefore direct mapping of value to checked prop
  return (
    <Checkbox
      {...field}
      {...props}
      disabled={disabled}
      value={field.value}
      checked={field.value}
    >
      {children}
    </Checkbox>
  );
}

FormCheckbox.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

export default FormCheckbox;
