import { TELECONSULTATION_MODES } from "../../constants/teleconsultationModes";
import mapTeleconsultationModeOptions from "./mapTeleconsultationModeOptions";

const teleconsultationModeOptions = [
  {
    value: "",
    label: "Teleconsultation mode",
    disabled: true,
  },
].concat(mapTeleconsultationModeOptions(Object.keys(TELECONSULTATION_MODES)));

export default teleconsultationModeOptions;
