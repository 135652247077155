import { TIME_PERIOD } from "constants/timePeriod";
import mapTimePeriodOptions from "./mapTimePeriodOptions";

const timePeriodOptions = [
  {
    value: "",
    label: "Time period",
    disabled: true,
  },
].concat(mapTimePeriodOptions(Object.keys(TIME_PERIOD)));

export default timePeriodOptions;
