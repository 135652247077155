function Lock() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.094 32.335c-2.444 0-4.434-1.997-4.434-4.452V20a4.46 4.46 0 013.547-4.36l.379-.078v-1.474c0-3.961 2.075-6.422 5.414-6.422s5.414 2.461 5.414 6.422v1.474l.378.078A4.46 4.46 0 0129.339 20v7.883c0 2.455-1.988 4.452-4.432 4.452h-9.813zm0-15.767A3.429 3.429 0 0011.676 20v7.883a3.428 3.428 0 003.418 3.431h9.813a3.427 3.427 0 003.416-3.431V20c0-1.892-1.532-3.432-3.416-3.432h-9.813zM20 8.685c-2.796 0-4.398 1.969-4.398 5.402v1.461h8.797v-1.461c0-3.433-1.603-5.402-4.399-5.402zm0 19.708a.51.51 0 01-.507-.51v-1.492l-.354-.093a3.456 3.456 0 01-2.59-3.342c0-1.912 1.549-3.466 3.451-3.466s3.451 1.555 3.451 3.466a3.46 3.46 0 01-2.589 3.342l-.355.092v1.493c0 .281-.227.51-.507.51zm0-7.884a2.446 2.446 0 00-2.438 2.447A2.444 2.444 0 0020 25.401a2.444 2.444 0 002.436-2.445A2.445 2.445 0 0020 20.509z"
      />
    </svg>
  );
}

export default Lock;
