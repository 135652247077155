function calculateAverageSessionDuration(sessions) {
  if (!sessions) {
    return 0;
  }

  let sessionsCounter = 0;
  let sessionsLength = 0;

  sessions.forEach((session) => {
    session["sessions"].forEach((singleSession) => {
      sessionsCounter++;
      sessionsLength += singleSession["sessionDuration"];
    });
  });

  return sessionsLength / sessionsCounter;
}

export default calculateAverageSessionDuration;
