import { useState } from "react";

import { INITIAL_STEP, SUCCESS_STEP } from "constants/steps";
import Card, { CARD_REGULAR } from "../Card";
import ErrorStep from "../ErrorStep";
import InitialStep from "./InitialStep";
import SuccessStep from "./SuccessStep";

function CheckPartnerDetails() {
  const [step, setStep] = useState(INITIAL_STEP);
  const [partnerDetails, setPartnerDetails] = useState(null);

  function renderContent() {
    if (step === INITIAL_STEP) {
      return (
        <InitialStep setStep={setStep} setPartnerDetails={setPartnerDetails} />
      );
    } else if (step === SUCCESS_STEP && partnerDetails) {
      return <SuccessStep setStep={setStep} partnerDetails={partnerDetails} />;
    }

    return <ErrorStep setStep={setStep} />;
  }

  return (
    <Card title="Check existing partner details" variant={CARD_REGULAR}>
      {renderContent()}
    </Card>
  );
}

export default CheckPartnerDetails;
