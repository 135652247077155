import styled from "@emotion/styled";

import FormInput from "../FormInput";

const Input = styled(FormInput)`
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`;

export default Input;
