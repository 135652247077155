import { useEffect, useState } from "react";
import { ErrorBoundary, Headline } from "@medi24-da2c/web-ui";
import { useTitle } from "@medi24-da2c/web-ui/hooks";
import styled from "@emotion/styled";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import CheckUsageStatistics from "components/CheckUsageStatistics";

dayjs.extend(utc);

const { RangePicker } = DatePicker;
const rangePresets = [
  { label: "Last 7 Days", value: [dayjs.utc().add(-7, "d"), dayjs.utc()] },
  { label: "Last 14 Days", value: [dayjs.utc().add(-14, "d"), dayjs.utc()] },
  {
    label: "Last 1 Months",
    value: [dayjs.utc().add(-1, "month"), dayjs.utc()],
  },
  {
    label: "Last 3 Months",
    value: [dayjs.utc().add(-3, "month"), dayjs.utc()],
  },
  {
    label: "Last 6 Months",
    value: [dayjs.utc().add(-6, "month"), dayjs.utc()],
  },
];

function StatisticsPage() {
  useTitle("Partners Dashboard");
  const [selectedDates, setSelectedDates] = useState([
    dayjs.utc().add(-6, "month"),
    dayjs.utc(),
  ]);

  useEffect(() => {
    localStorage.setItem(
      "rangeStartAt",
      dayjs.utc().add(-6, "month").toISOString()
    );
    localStorage.setItem("rangeEndAt", dayjs.utc().toISOString());
  }, []);

  const disabledDate = (current) => {
    // Get the selected date
    const selectedDate = selectedDates[0];
    // Calculate the date 3 months before and after the selected date
    const threeMonthsBefore = dayjs(selectedDate).subtract(6, "months");
    const threeMonthsAfter = dayjs(selectedDate).add(6, "months");

    // Disable dates outside of this range
    return (
      current.isBefore(threeMonthsBefore) ||
      current.isAfter(threeMonthsAfter) ||
      current > dayjs.utc().endOf("day")
    );
  };

  function onDateRangeChange(dates) {
    setSelectedDates(dates);
    localStorage.setItem("rangeStartAt", dates[0].utc().toISOString());
    localStorage.setItem("rangeEndAt", dates[1].utc().toISOString());
  }

  return (
    <ErrorBoundary>
      <Head>
        <div className="title">
          <Headline>Statistics</Headline>
        </div>
        <RangePicker
          format="YYYY-MM-DD"
          disabledDate={disabledDate}
          onCalendarChange={onDateRangeChange}
          presets={rangePresets}
          defaultValue={[dayjs.utc().add(-6, "month"), dayjs.utc()]}
          size="large"
        />
      </Head>
      <CheckUsageStatistics />
    </ErrorBoundary>
  );
}

export default StatisticsPage;

const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    flex: 1;
  }
`;
