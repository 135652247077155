function calculateAverageRating(ratings) {
  if (!ratings) {
    return 0;
  }

  let ratingsPeriods = Object.keys(ratings);
  let ratingsSum = 0;
  let ratingsCounter = 0;

  ratingsPeriods.forEach((period) => {
    ratingsCounter++;
    ratingsSum += ratings[period];
  });

  let averageRating = ratingsSum / ratingsCounter;

  return averageRating.toFixed(2);
}

export default calculateAverageRating;
