import { ErrorBoundary, Headline } from "@medi24-da2c/web-ui";
import { useTitle } from "@medi24-da2c/web-ui/hooks";
import Cards from "components/Cards";
import AddNewPartner from "components/AddNewPartner";
import CheckPartnerDetails from "components/CheckPartnerDetails";

function PartnersManagementPage() {
  useTitle("Partners Management");

  return (
    <ErrorBoundary>
      <Headline>Partners management</Headline>
      <Cards>
        <AddNewPartner />
        <CheckPartnerDetails />
      </Cards>
    </ErrorBoundary>
  );
}

export default PartnersManagementPage;
