function csvFileName(partnerName, partnerCode, batchId, timestamp) {
  const date = new Date(timestamp);
  const formattedDate = `${date.getDate()}_${
    date.getMonth() + 1
  }_${date.getFullYear()}`;
  const formattedName = partnerName.split(" ").join("_").toLowerCase();

  return `${formattedName}[${partnerCode}]_${formattedDate}_${batchId}.csv`;
}

export default csvFileName;
