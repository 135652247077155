import PropTypes from "prop-types";

import { ErrorBoundary } from "@medi24-da2c/web-ui";
import Headline from "./Headline";
import Content from "./Content";
import Text from "./Text";
import CloseButton from "./CloseButton";
import Loader from "./Loader";

function BasicStep({ headline, controls, children }) {
  return (
    <ErrorBoundary>
      <Headline>{headline}</Headline>
      <Content>{children}</Content>
      {controls}
    </ErrorBoundary>
  );
}

BasicStep.propTypes = {
  headline: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  controls: PropTypes.node,
};

BasicStep.Text = Text;
BasicStep.CloseButton = CloseButton;
BasicStep.Loader = Loader;

export default BasicStep;
