import styled from "@emotion/styled";

import { Text } from "@medi24-da2c/web-ui";
import { fontLarge } from "@medi24-da2c/web-ui/design-tokens";

const Headline = styled(Text)`
  margin: 0;
  font-size: ${fontLarge};
`.withComponent("h3");

export default Headline;
