import { useEffect, useState, useCallback } from "react";
import { Formik } from "formik";
import { Button } from "@medi24-da2c/web-ui";
import { DatePicker, Input } from "react-rainbow-components";
import { useGetSessions, getSessions } from "api/sessions";
import { useGetPartners } from "api/partners";
import Cards from "components/Cards";
import BasicForm from "../BasicForm";
import FormSelect from "../FormSelect";
import Card, { CARD_REGULAR } from "../Card";
import { ChartComponent, CHART_TYPES } from "./ChartComponent";
import PartnerMultiselect from "./Multiselect";
import validateForm from "./validateForm";
import timePeriodOptions from "./timePeriodOptions";
import createChartDataset from "./createChartDataset";
import createChartOptions from "./createChartOptions";
import mapSessionsToChartDataset from "./mapSessionsToChartDataset";
import calculateAverageSessionDuration from "./calculateAverageSessionDuration";
import displayDurationTime from "./displayDurationTime";

const inputStyles = {
  width: 300,
  marginTop: 10,
};

function Sessions() {
  const chartLabel = "Number of Sessions";
  const options = createChartOptions("Sessions");

  let today = new Date();
  let sixMonthsEarlier = new Date().setMonth(today.getMonth() - 6);

  const [loading, setLoading] = useState(false);
  const [excludeUsersWithName, setExcludeUsersWithName] = useState(false);
  const [disableExcludedNameField, setDisableExcludedNameField] =
    useState(true);
  const [excludedName, setExcludedName] = useState("test");
  const [partnersIds, setPartnersIds] = useState("");
  const [sinceStartPeriod, setSinceStartPeriod] = useState(false);
  const [isDatePickerDisabled, setIsDatePickerDisabled] = useState(false);
  const [existingPartners, setExistingPartners] = useState([]);
  const [upTodate, setUpToDate] = useState(today.toISOString());
  const [startDate, setStartDate] = useState(
    new Date(sixMonthsEarlier).toISOString()
  );

  const { partners } = useGetPartners();
  const { sessions } = useGetSessions(startDate, upTodate, "");

  const [data, setData] = useState(
    createChartDataset(mapSessionsToChartDataset(sessions), chartLabel)
  );
  const [averageSessionDuration, setAverageSessionDuration] = useState(
    calculateAverageSessionDuration(sessions)
  );

  useEffect(() => {
    setData(
      createChartDataset(mapSessionsToChartDataset(sessions), chartLabel)
    );
    setAverageSessionDuration(calculateAverageSessionDuration(sessions));
  }, [sessions]);

  useEffect(() => {
    setExistingPartners(partners);
  }, [partners]);

  async function handleSubmit(values) {
    setLoading(true);
    try {
      const startFrom = new Date(startDate).toISOString();
      const endDate = new Date(upTodate).toISOString();
      let period = "";
      let excludeNamePattern = "";

      if (values["timePeriod"]) {
        period = values["timePeriod"];
      }

      if (excludeUsersWithName && excludedName) {
        excludeNamePattern = excludedName;
      }

      getSessions(
        startFrom,
        endDate,
        partnersIds,
        period,
        excludeNamePattern
      ).then((newSessions) => {
        setData(
          createChartDataset(mapSessionsToChartDataset(newSessions), chartLabel)
        );
        setAverageSessionDuration(calculateAverageSessionDuration(newSessions));
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
    }
  }

  function updateStartDate(value) {
    setStartDate(new Date(value).toISOString());
  }

  function updateUpToDate(value) {
    setUpToDate(new Date(value).toISOString());
  }

  function setExcludeName() {
    setExcludeUsersWithName(!excludeUsersWithName);
    setDisableExcludedNameField(!disableExcludedNameField);
  }

  function updateExcludedName(value) {
    setExcludedName(value.target.value);
  }

  function setStartPeriod() {
    if (sinceStartPeriod === false) {
      setStartDate(new Date(2020, 10, 9).toISOString());
      setUpToDate(new Date().toISOString());
      setIsDatePickerDisabled(true);
    } else {
      setIsDatePickerDisabled(false);
    }
    setSinceStartPeriod(!sinceStartPeriod);
  }

  const updatePartners = useCallback(
    (args) => {
      let partnersList = [];

      args.forEach((partner) => partnersList.push(partner["value"]));

      setPartnersIds(partnersList.join());
    },
    [setPartnersIds]
  );

  return (
    <div>
      <div>
        <Formik
          initialValues={{
            timePeriod: "",
          }}
          validate={validateForm}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, dirty }) => (
            <BasicForm onSubmit={handleSubmit} noValidate>
              <BasicForm.Fields>
                <Cards>
                  <Card title="Sessions Parameters" variant={CARD_REGULAR}>
                    <PartnerMultiselect
                      partners={existingPartners}
                      onPartnersChange={updatePartners}
                    />

                    <FormSelect
                      name="timePeriod"
                      label="Select time period"
                      options={timePeriodOptions}
                    />

                    <Input
                      value={sinceStartPeriod}
                      className="rainbow-m-around_medium"
                      type="checkbox"
                      label="Since product launch"
                      onChange={setStartPeriod}
                    />

                    <div>
                      <DatePicker
                        formatStyle="medium"
                        value={startDate}
                        label="Start Date"
                        disabled={isDatePickerDisabled}
                        onChange={updateStartDate}
                      />
                    </div>
                    <div>
                      <DatePicker
                        formatStyle="medium"
                        value={upTodate}
                        label="Up To Date"
                        disabled={isDatePickerDisabled}
                        onChange={updateUpToDate}
                      />
                    </div>

                    <br />

                    <div>
                      <Button
                        type="submit"
                        disabled={loading}
                        data-testid="sessions-submit-button"
                      >
                        Refresh
                      </Button>
                    </div>
                  </Card>
                  <Card title="Additional Settings" variant={CARD_REGULAR}>
                    <Input
                      value={excludeUsersWithName}
                      name="excludeName"
                      className="rainbow-m-around_medium"
                      type="checkbox"
                      label="Exclude Users With Name Pattern"
                      onChange={setExcludeName}
                    />
                    <Input
                      placeholder="test"
                      type="text"
                      className="rainbow-p-around_medium"
                      name="excludedNamePattern"
                      value={excludedName}
                      style={inputStyles}
                      onChange={updateExcludedName}
                      disabled={disableExcludedNameField}
                    />
                  </Card>
                </Cards>
              </BasicForm.Fields>

              {loading && <BasicForm.Loader />}

              <br />
            </BasicForm>
          )}
        </Formik>
      </div>

      <h1>
        Average Session Duration: {displayDurationTime(averageSessionDuration)}
      </h1>
      <br />

      <div>
        <ChartComponent
          options={options}
          data={data}
          chartType={CHART_TYPES.LINE_CHART}
        />
      </div>
    </div>
  );
}

export default Sessions;
