import PropTypes from "prop-types";

import { Button } from "@medi24-da2c/web-ui";
import { INITIAL_STEP } from "constants/steps";
import BasicStep from "../BasicStep";

function SuccessStep({ setStep, numberOfDeactivatedCodes }) {
  function renderSuccesMessage() {
    if (numberOfDeactivatedCodes === 0) {
      return (
        <BasicStep.Text>
          No new codes have been deactivated. Most likely they were invalidated
          earlier.
        </BasicStep.Text>
      );
    }

    return (
      <BasicStep.Text>
        <strong>{numberOfDeactivatedCodes}</strong>{" "}
        {numberOfDeactivatedCodes === 1 ? "code has" : "codes have"} been
        successfully deactivated.
      </BasicStep.Text>
    );
  }

  return (
    <BasicStep
      headline="Success!"
      controls={
        <Button onClick={() => setStep(INITIAL_STEP)}>
          Deactivate more codes
        </Button>
      }
    >
      {renderSuccesMessage()}
    </BasicStep>
  );
}

SuccessStep.propTypes = {
  setStep: PropTypes.func.isRequired,
  numberOfDeactivatedCodes: PropTypes.number.isRequired,
};

export default SuccessStep;
