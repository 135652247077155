import styled from "@emotion/styled";

import { Text as TextBase } from "@medi24-da2c/web-ui";
import { spacingSmall } from "@medi24-da2c/web-ui/design-tokens";

const Text = styled(TextBase)`
  margin: ${spacingSmall} 0;

  &:first-of-type {
    margin-top: 0;
  }
`;

export default Text;
