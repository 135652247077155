import { ErrorBoundary, Headline } from "@medi24-da2c/web-ui";
import { useTitle } from "@medi24-da2c/web-ui/hooks";
import Cards from "components/Cards";
import GenerateActivationCodes from "components/GenerateActivationCodes";
import DeactivateCodes from "components/DeactivateCodes";

function CodesManagementPage() {
  useTitle("Activation Codes Management");

  return (
    <ErrorBoundary>
      <Headline>Activation codes management</Headline>
      <Cards>
        <GenerateActivationCodes />
        <DeactivateCodes />
      </Cards>
    </ErrorBoundary>
  );
}

export default CodesManagementPage;
