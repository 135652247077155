import fetchData from "./fetchData";

export async function getStatisticsByPartnerId(partnerId, range) {
  let url = `/sessions/?partnerId=${partnerId}`;
  if (range && range.length > 1) {
    url = `/sessions/?partnerId=${partnerId}&startAtFrom=${range[0]}&startAtTo=${range[1]}`;
  }
  const statisticsBatch = await fetchData(url, {
    method: "GET",
    responseType: "blob",
  });
  return statisticsBatch;
}

export async function getAllStatistics(range) {
  let url = `/sessions`;
  if (range && range.length > 1) {
    url = `/sessions?startAtFrom=${range[0]}&startAtTo=${range[1]}`;
  }
  const statisticsBatch = await fetchData(url, {
    method: "GET",
    responseType: "blob",
  });
  return statisticsBatch;
}
