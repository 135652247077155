import styled from "@emotion/styled";

import { colorPaleBlueLight } from "@medi24-da2c/web-ui/default-theme";

const FocusIndicator = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.isFocusVisible ? colorPaleBlueLight : "transparent"};
`;

export default FocusIndicator;
