import { ELIGIBILITY_CHECK_TYPES } from "../../constants/eligibilityCheckTypes";
import mapEligibilityCheckTypeOptions from "./mapEligibilityCheckTypeOptions";

const eligibilityCheckTypeOptions = [
  {
    value: "",
    label: "Eligibility check type",
    disabled: true,
  },
].concat(mapEligibilityCheckTypeOptions(Object.keys(ELIGIBILITY_CHECK_TYPES)));

export default eligibilityCheckTypeOptions;
