import { LANGUAGE_CODES } from "constants/languageCodes";
import languageCodeToName from "utils/languageCodeToName";

const all_languages = Object.keys(LANGUAGE_CODES);

function mapLanguageOptions(language_codes) {
  if (!language_codes) {
    language_codes = all_languages;
  }
  return language_codes
    .map((code) => {
      return {
        value: code,
        label: languageCodeToName(code),
      };
    })
    .filter((a) => a.label)
    .sort((a, b) => a.label.localeCompare(b.label));
}

export default mapLanguageOptions;
