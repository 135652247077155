import languageCodeToName from "utils/languageCodeToName";

function formatAvailableLanguages(languages) {
  return languages.reduce((allLanguages, language, index) => {
    // Check if language is supported
    if (languageCodeToName(language)) {
      return allLanguages.concat(
        (index ? ", " : "") + languageCodeToName(language)
      );
    }

    return allLanguages;
  }, "");
}

export default formatAvailableLanguages;
