import PropTypes from "prop-types";

import ExistingPartnerSelectForm from "../ExistingPartnerSelectForm";
import { SUCCESS_STEP } from "../../constants/steps";

function InitialStep({ setStep, setPartnerDetails }) {
  return (
    <ExistingPartnerSelectForm
      setStep={setStep}
      setPartnerDetails={setPartnerDetails}
      submitButtonLabel="Check details"
      nextStep={SUCCESS_STEP}
    />
  );
}

InitialStep.propTypes = {
  setStep: PropTypes.func.isRequired,
  setPartnerDetails: PropTypes.func.isRequired,
};

export default InitialStep;
