import { useState } from "react";
import { RadioButtonGroup } from "react-rainbow-components";
import LineChart from "./LineChart";
import BarChart from "./BarChart";

export const CHART_TYPES = {
  LINE_CHART: "LINE_CHART",
  BAR_CHART: "BAR_CHART",
};

const options = [
  { value: CHART_TYPES.LINE_CHART, label: "Line" },
  { value: CHART_TYPES.BAR_CHART, label: "Bar" },
];

export function ChartComponent(parameters) {
  const [chartType, setChartType] = useState(parameters.chartType);

  function changeChartType(value) {
    setChartType(value.target.value);
  }

  const displayChart = () => {
    switch (chartType) {
      case CHART_TYPES.LINE_CHART:
        return (
          <LineChart options={parameters.options} data={parameters.data} />
        );
      case CHART_TYPES.BAR_CHART:
        return <BarChart options={parameters.options} data={parameters.data} />;

      default:
        return (
          <LineChart options={parameters.options} data={parameters.data} />
        );
    }
  };

  return (
    <div>
      <div className="rainbow-p-around_x-large rainbow-align-content_center">
        <RadioButtonGroup
          id="radio-button-group-component-1"
          options={options}
          value={chartType}
          onChange={changeChartType}
        />
      </div>

      <div>{displayChart()}</div>
    </div>
  );
}
