import PropTypes from "prop-types";

import FormSelect from "../FormSelect";
import existingPartnersOptions from "./existingPartnersOptions";

function ExistingPartnersSelect({ partners }) {
  return (
    <FormSelect
      name="existingPartnerId"
      type="text"
      label="Select existing partner"
      disabled={!partners}
      options={existingPartnersOptions(partners)}
    />
  );
}

ExistingPartnersSelect.propTypes = {
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    })
  ),
};

export default ExistingPartnersSelect;
