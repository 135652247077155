import { useState } from "react";

import { INITIAL_STEP, SUCCESS_STEP } from "constants/steps";
import Card, { CARD_REGULAR } from "../Card";
import ErrorStep from "../ErrorStep";
import InitialStep from "./InitialStep";
import SuccessStep from "./SuccessStep";

function DeactivateCodes() {
  const [step, setStep] = useState(INITIAL_STEP);
  const [numberOfDeactivatedCodes, setNumberOfDeactivatedCodes] = useState(0);

  function renderContent() {
    if (step === INITIAL_STEP) {
      return (
        <InitialStep
          setStep={setStep}
          setNumberOfDeactivatedCodes={setNumberOfDeactivatedCodes}
        />
      );
    } else if (step === SUCCESS_STEP) {
      return (
        <SuccessStep
          setStep={setStep}
          numberOfDeactivatedCodes={numberOfDeactivatedCodes}
        />
      );
    }

    return <ErrorStep setStep={setStep} />;
  }

  return (
    <Card title="Deactivate activation codes" variant={CARD_REGULAR}>
      {renderContent()}
    </Card>
  );
}

export default DeactivateCodes;
