import { useQuery } from "react-query";
import fetchData from "./fetchData";

const SESSIONS_QUERY_KEY = "sessions/sessions";

export async function getSessions(
  startAtFrom,
  startAtTo,
  partnerId,
  period,
  excludeNamePattern
) {
  let queryString = `/sessions/sessions?startAtFrom=${startAtFrom}&startAtTo=${startAtTo}`;
  if (partnerId) {
    queryString += `&partnerId=${partnerId}`;
  }
  if (period) {
    queryString += `&period=${period}`;
  }
  if (excludeNamePattern) {
    queryString += `&excludeNamePattern=${excludeNamePattern}`;
  }
  const sessions = await fetchData(queryString, {
    method: "GET",
  });
  return sessions;
}

export function useGetSessions(startDate, endDate) {
  const { data: sessions, status } = useQuery(SESSIONS_QUERY_KEY, () =>
    getSessions(startDate, endDate, null)
  );
  return { sessions, status };
}
