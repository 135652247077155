import { selfRegistrationResetLeftActivationCodesDateTypeToLabel } from "../../utils/selfRegistrationResetLeftActivationCodesDateTypeToLabel";

function mapSelfRegistrationResetLeftActivationCodesDateTypeOptions(
  selfRegistrationResetLeftActivationCodesDateTypes
) {
  return selfRegistrationResetLeftActivationCodesDateTypes.map((type) => {
    return {
      value: type,
      label: selfRegistrationResetLeftActivationCodesDateTypeToLabel(type),
    };
  });
}

export default mapSelfRegistrationResetLeftActivationCodesDateTypeOptions;
