import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";

import { ErrorBoundary, Button } from "@medi24-da2c/web-ui";
import { SUCCESS_STEP, ERROR_STEP } from "constants/steps";
import { useGetPartners } from "api/partners";
import { createActivationCodes } from "api/activationCodes";
import BasicForm from "../BasicForm";
import FormInput from "../FormInput";
import FormCheckbox from "../FormCheckbox";
import ExistingPartnersSelect from "../ExistingPartnersSelect";
import validateForm from "./validateForm";

function InitialStep({ setStep, setCodesDetails }) {
  // Get list of partners to generate existing partners select options
  const { partners, status } = useGetPartners();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Adds slight delay to prevent flickering in case of initial query errors
    const timeout = setTimeout(() => {
      if (status === "error") {
        setStep(ERROR_STEP);
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, [status, setStep]);

  useEffect(() => {
    setLoading(status === "loading");
  }, [status]);

  async function handleSubmit(values) {
    const { existingPartnerId, numberOfActivationCodes, preGenerated } = values;
    setLoading(true);
    setCodesDetails(null);

    try {
      const codesDetails = await createActivationCodes(
        existingPartnerId,
        numberOfActivationCodes,
        preGenerated
      );
      setLoading(false);
      setCodesDetails(codesDetails);
      setStep(SUCCESS_STEP);
    } catch {
      setLoading(false);
      setStep(ERROR_STEP);
    }
  }

  return (
    <ErrorBoundary>
      <Formik
        initialValues={{
          existingPartnerId: "",
          numberOfActivationCodes: "",
          preGenerated: false,
        }}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, dirty }) => (
          <BasicForm onSubmit={handleSubmit} noValidate>
            <BasicForm.Fields>
              <ExistingPartnersSelect partners={partners} />
              <FormInput
                name="numberOfActivationCodes"
                type="text"
                label="How many codes to generate?"
                spellCheck="false"
                autoComplete="off"
                inputMode="numeric"
                pattern="[0-9]*"
              />
              <FormCheckbox name="preGenerated">
                Pre-generated for Self Registration
              </FormCheckbox>
            </BasicForm.Fields>
            {loading && <BasicForm.Loader />}
            <Button
              type="submit"
              disabled={!dirty || loading}
              data-testid="generate-codes-button"
            >
              Generate codes
            </Button>
          </BasicForm>
        )}
      </Formik>
    </ErrorBoundary>
  );
}

InitialStep.propTypes = {
  setStep: PropTypes.func.isRequired,
  setCodesDetails: PropTypes.func.isRequired,
};

export default InitialStep;
