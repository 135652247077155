import { useState } from "react";

import { INITIAL_STEP, SUCCESS_STEP } from "constants/steps";
import Card, { CARD_REGULAR } from "../Card";
import ErrorStep from "../ErrorStep";
import InitialStep from "./InitialStep";
import SuccessStep from "./SuccessStep";

function GenerateActivationCodes() {
  const [step, setStep] = useState(INITIAL_STEP);
  const [codesDetails, setCodesDetails] = useState(null);

  function renderContent() {
    if (step === INITIAL_STEP) {
      return (
        <InitialStep setStep={setStep} setCodesDetails={setCodesDetails} />
      );
    } else if (step === SUCCESS_STEP && codesDetails) {
      return <SuccessStep setStep={setStep} codesDetails={codesDetails} />;
    }

    return <ErrorStep setStep={setStep} />;
  }

  return (
    <Card
      title="Generate activation codes for an existing partner"
      variant={CARD_REGULAR}
    >
      {renderContent()}
    </Card>
  );
}

export default GenerateActivationCodes;
