import PropTypes from "prop-types";

import { Button } from "@medi24-da2c/web-ui";
import { INITIAL_STEP } from "constants/steps";
import { BOT_NAMES } from "constants/botNames";
import countryCodeToName from "utils/countryCodeToName";
import languageCodeToName from "utils/languageCodeToName";
import formatAvailableLanguages from "utils/formatAvailableLanguages";
import { botCodeToName } from "utils/botCodeToName";
import BasicStep from "../BasicStep";
import { LANGUAGE_CODES } from "../../constants/languageCodes";
import { COUNTRY_CODES } from "../../constants/countryCodes";

function SuccessStep({ setStep, partnerDetails }) {
  const {
    name,
    code,
    country,
    defaultLanguage,
    botName,
    hasHotline,
    hotlineNumber,
    hotlineSecondNumber,
    hasTeleconsultation,
    teleconsultationNumber,
    teleconsultationWebsite,
    teleconsultationCountry,
    hasHealthcareWebsite,
    healthcareWebsite,
    hasSymptomChecker,
    hasDoctorChat,
    doctorChatPartnerName,
    doctorChatAvailableLanguages,
    doctorChatPhysicianCountry,
  } = partnerDetails;

  return (
    <BasicStep
      headline="Success!"
      controls={
        <Button onClick={() => setStep(INITIAL_STEP)}>
          Add another partner
        </Button>
      }
    >
      <BasicStep.Text>
        The new partner <strong>{name}</strong> has been created successfully.
      </BasicStep.Text>
      <BasicStep.Text>
        Activation code prefix for this partner is <strong>{code}</strong>.
      </BasicStep.Text>
      <BasicStep.Text>
        Country for this partner is{" "}
        <strong>{countryCodeToName(country)}</strong> and default language is{" "}
        <strong>{languageCodeToName(defaultLanguage)}</strong>.
      </BasicStep.Text>
      <BasicStep.Text>
        Bot name for this partner is <strong>{botCodeToName(botName)}</strong>.
      </BasicStep.Text>
      {hasHotline && (
        <BasicStep.Text>
          Provided hotline phone number is <strong>{hotlineNumber}</strong>.{" "}
          {hotlineSecondNumber && (
            <>
              Second optional phone number is:{" "}
              <strong>{hotlineSecondNumber}</strong>.
            </>
          )}
        </BasicStep.Text>
      )}
      {hasTeleconsultation && (
        <BasicStep.Text>
          {teleconsultationNumber && (
            <>
              Provided teleconsultation phone number is{" "}
              <strong>{teleconsultationNumber}</strong>.{" "}
            </>
          )}
          {teleconsultationWebsite && (
            <>
              Teleconsultation website address is{" "}
              <strong>{teleconsultationWebsite}</strong>.{" "}
            </>
          )}
          {teleconsultationCountry && (
            <>
              Selected teleconsultation country is{" "}
              <strong>{countryCodeToName(teleconsultationCountry)}</strong>.
            </>
          )}
        </BasicStep.Text>
      )}
      {hasHealthcareWebsite && (
        <BasicStep.Text>
          Provided link to internal healthcare provider's website is{" "}
          <strong>{healthcareWebsite}</strong>.
        </BasicStep.Text>
      )}
      {hasSymptomChecker && (
        <BasicStep.Text>
          Symptom checker service is enabled and provided by{" "}
          <strong>Sensely</strong>.
        </BasicStep.Text>
      )}
      {hasDoctorChat && (
        <BasicStep.Text>
          Doctor chat service is enabled and provided by <strong>ABI</strong>.
          ABI partner name is <strong>{doctorChatPartnerName}</strong>.
          Physician country is{" "}
          <strong>{countryCodeToName(doctorChatPhysicianCountry)}</strong> and
          available languages are:{" "}
          <strong>
            {formatAvailableLanguages(doctorChatAvailableLanguages)}
          </strong>
          .
        </BasicStep.Text>
      )}
    </BasicStep>
  );
}

SuccessStep.propTypes = {
  setStep: PropTypes.func.isRequired,
  partnerDetails: PropTypes.shape({
    name: PropTypes.string.isRequired,
    defaultLanguage: PropTypes.oneOf(Object.keys(LANGUAGE_CODES)),
    country: PropTypes.oneOf(Object.keys(COUNTRY_CODES)),
    botName: PropTypes.oneOf(Object.keys(BOT_NAMES)),
    code: PropTypes.string.isRequired,
    hasHotline: PropTypes.bool.isRequired,
    hotlineNumber: PropTypes.string,
    hotlineSecondNumber: PropTypes.string,
    hasTeleconsultation: PropTypes.bool.isRequired,
    teleconsultationNumber: PropTypes.string,
    teleconsultationWebsite: PropTypes.string,
    teleconsultationCountry: PropTypes.string,
    hasHealthcareWebsite: PropTypes.bool.isRequired,
    healthcareWebsite: PropTypes.string,
    hasSymptomChecker: PropTypes.bool.isRequired,
    hasDoctorChat: PropTypes.bool.isRequired,
    doctorChatAvailableLanguages: PropTypes.array,
    doctorChatPhysicianCountry: PropTypes.string,
  }),
};

export default SuccessStep;
