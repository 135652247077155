import React, { useEffect, useState } from "react";
import { MultiSelect, Option } from "react-rainbow-components";
import existingPartnersOptions from "../ExistingPartnersSelect/existingPartnersOptions";

const containerStyles = {
  maxWidth: 400,
};

function PartnerMultiselect({ partners, onPartnersChange }) {
  const [selectedPartners, setPartners] = useState([]);

  const partnersList = existingPartnersOptions(partners);

  useEffect(() => {
    onPartnersChange(selectedPartners);
  }, [selectedPartners, onPartnersChange]);

  return (
    <MultiSelect
      name="existingPartnerId"
      id="existing-partners"
      label="Select existing partners"
      placeholder="Select All Partners"
      style={containerStyles}
      className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
      value={selectedPartners}
      onChange={setPartners}
      bottomHelpText="You can select several options."
      showCheckbox
    >
      {partnersList.map(({ value, label, disabled = false }) => (
        <Option
          key={value}
          name={value}
          value={value}
          label={label}
          disabled={disabled}
        />
      ))}
    </MultiSelect>
  );
}

export default PartnerMultiselect;
